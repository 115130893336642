<div class="d-flex flex-wrap gap-2">
  @for (role of roles;track role.name) {
    <div class="form-check form-check-inline form-switch" [popoverTitle]="role.name | humanReadable"
         [ngbPopover]="role.description" triggers="mouseenter:mouseleave">
      <input type="checkbox" class="form-check-input" id="role-{{role.name}}" autocomplete="off">
      <label class="form-check-label" for="role-{{role.name}}">{{ role.name | humanReadable }}</label>
    </div>
  } @empty {
    <i>No permissions available</i>
  }
</div>
