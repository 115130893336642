import { Component, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { faEdit, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounce } from 'lodash';
import AbstractInput from '../../../utils/AbstractInput';
import { CompanyDto, CompanyRefDto } from '../../model/control-panel-api';
import { CompaniesService } from '../../service/companies.service';

@Component({
  selector: 'app-company-input',
  templateUrl: './company-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CompanyInputComponent,
    },
  ],
  host: {
    class: 'd-flex flex-row align-items-center',
  },
})
export class CompanyInputComponent extends AbstractInput<CompanyRefDto | null> implements OnDestroy {
  public readonly faTrashCan = faTrashCan;
  public readonly faEdit = faEdit;

  @Input()
  public nullable = true;

  @Input()
  public companyId?: number;

  @Input()
  public readonly = false;

  public companies: CompanyDto[] = [];
  public query = '';
  public readonly debouncedLoadCompanies = debounce(this.loadCompanies, 500);

  private loadedQuery: string | null = null;

  @ViewChild('modal')
  private readonly modal: TemplateRef<any>;

  public constructor(private readonly companiesService: CompaniesService,
                     private readonly modalService: NgbModal) {
    super();
  }

  public ngOnDestroy(): void {
    this.debouncedLoadCompanies.cancel();
  }

  public openModal(): void {
    this.loadCompanies();
    this.modalService.open(this.modal, {})
      .result
      .then((result: CompanyRefDto | null | undefined) => {
        if (result === undefined) return;
        this.value = result;
        this.markAsTouched();
      }, () => {
        // ignore when dismissed
      });
  }

  private loadCompanies(): void {
    const query = this.query;
    if (this.loadedQuery === query) return;
    this.companiesService.query({
      query,
      page: 0,
      pageSize: 25,
    })
      .subscribe(companies => {
        this.companies = companies.data;
        this.loadedQuery = query;
      });
  }
}
