import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormControls } from '../../../utils/AbstractGroupInput';
import { KeycloakUserUpdateDto, RoleDto } from '../../model/control-panel-api';
import getFieldError from '../../utils/getFieldError';
import { PHONE_NUMBER_VALIDATOR } from '../../utils/regexValidator';

@Component({
  selector: 'app-keycloak-user-input',
  templateUrl: './keycloak-user-input.component.html',
  host: {
    class: 'row g-3',
  },
})
export class KeycloakUserInputComponent {
  @Input()
  public formGroup: FormGroup<FormControls<KeycloakUserUpdateDto>>;

  @Input()
  public readonly = false;

  @Input()
  public roles: readonly RoleDto[];

  protected readonly getFieldError = getFieldError;

  public static createForm(): FormGroup<FormControls<KeycloakUserUpdateDto>> {
    return new FormGroup<FormControls<KeycloakUserUpdateDto>>({
      email: new FormControl('', {nonNullable: true, validators: [Validators.required, Validators.email]}),
      firstName: new FormControl('', {nonNullable: true, validators: Validators.required}),
      lastName: new FormControl('', {nonNullable: true, validators: Validators.required}),
      phoneNumber: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required, PHONE_NUMBER_VALIDATOR],
      }),
      enabled: new FormControl(false, {nonNullable: true}),
      emailVerified: new FormControl(false, {nonNullable: true}),
      roles: new FormControl([] as string[], {nonNullable: true}),
    });
  }
}
