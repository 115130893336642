import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { CrudService, EntityPositionData, IdEntity } from '../../service/crud.service';
import { forkJoin, Observable, Subscription, tap } from 'rxjs';
import { InputConfig } from '../entity-form/entity-form.component';
import { FormGroup } from '@angular/forms';
import { FormControls } from '../../../utils/AbstractGroupInput';
import { UserService } from '../../service/user.service';
import { ActivatedRoute } from '@angular/router';
import { extractQueryRequest } from '../../model/page';
import { Location } from '@angular/common';
import { StatusInfo } from '../status-banner/status-banner.component';

export interface DetailsPageConfiguration<STATE, DETAILS extends IdEntity, UPDATE extends object> {
  headline: string;
  service: CrudService<any, DETAILS, UPDATE, any, any, any>;
  stateFetch: Observable<STATE>;
  inputsFactory: (state: STATE | null, value: UPDATE | null) => InputConfig<UPDATE>[];
  resource: string;
  newEntityTemplate: DETAILS;
  controlsBuilder: (entity: DETAILS) => FormControls<UPDATE>;
}

@Component({
  selector: 'app-details-page',
  templateUrl: './details-page.component.html',
})
export class DetailsPageComponent<STATE, DETAILS extends IdEntity, UPDATE extends object> implements OnInit, OnDestroy {
  @Input()
  public configuration: DetailsPageConfiguration<STATE, DETAILS, UPDATE>;

  @Input()
  public breadcrumb: TemplateRef<{ loading: boolean; value: UPDATE; newEntity: boolean }>;

  @Output()
  public readonly onStateChange = new EventEmitter<STATE>();

  protected status: StatusInfo | null = null;
  protected entity: DETAILS;
  protected form: FormGroup<FormControls<UPDATE>> | null = null;
  protected entityId: number | null;
  protected positionData: EntityPositionData | null = null;
  private state: STATE | null = null;
  private isLoadingData = true;
  private isLoadingEntity = true;
  private routeSubscription: Subscription | null = null;

  public constructor(private readonly userService: UserService,
                     private readonly route: ActivatedRoute,
                     private readonly location: Location) {
  }

  protected get loading(): boolean {
    return this.isLoadingData || this.isLoadingEntity;
  }

  protected get value(): UPDATE {
    return this.form?.value as UPDATE;
  }

  protected get inputs(): InputConfig<UPDATE>[] {
    return this.configuration.inputsFactory(this.state, this.form?.value as any);
  }

  public ngOnInit(): void {
    this.loadState();

    if (this.route.snapshot.routeConfig?.path?.includes('create')) {
      this.updateEntity(this.configuration.newEntityTemplate);
      this.isLoadingEntity = false;
      this.entityId = null;
    } else {
      this.routeSubscription = this.route.paramMap.subscribe(paramMap => {
        const id = paramMap.get('id');
        if (!id) throw new Error('No id found');
        this.entityId = parseInt(id);
        this.status = null;

        this.isLoadingEntity = true;
        forkJoin([
          this.configuration.service.fetchDetails(this.entityId)
            .pipe(tap(group => this.updateEntity(group))),
          this.configuration.service.getEntityPosition(this.entityId, extractQueryRequest(this.route.snapshot.queryParamMap))
            .pipe(tap(positionData => this.positionData = positionData)),
        ]).subscribe({
          error: error => this.status = {
            type: 'danger',
            title: 'Error loading entity!',
            content: error,
          },
          complete: () => this.isLoadingEntity = false,
        });
      });
    }
  }

  public ngOnDestroy(): void {
    this.routeSubscription?.unsubscribe();
  }

  protected save(): void {
    if (this.loading) {
      return;
    }

    this.isLoadingEntity = true;

    if (this.entityId === null) {
      this.configuration.service.createEntity(this.form!.value as any).subscribe({
        next: entity => {
          this.isLoadingEntity = false;
          this.updateEntity(entity);
          this.location.replaceState(`${this.location.path(true)}/../${entity.id}`);
          this.entityId = entity.id;
          this.status = {
            type: 'success',
            title: 'The entity was created successfully!',
            timeout: 5000,
          };
        },
        error: error => {
          this.isLoadingEntity = false;
          this.status = {
            type: 'danger',
            title: 'Failed to create entity!',
            content: error,
          };
        },
      });
    } else {
      this.configuration.service.updateEntity(this.entityId, this.form!.value as any).subscribe({
        next: group => {
          this.isLoadingEntity = false;
          this.updateEntity(group);
          this.status = {
            type: 'success',
            title: 'The entity was updated successfully!',
            timeout: 5000,
          };
        },
        error: error => {
          this.status = {
            type: 'danger',
            title: 'Failed to save entity!',
            content: error,
          };
          this.isLoadingEntity = false;
        },
      });
    }
  }

  private updateEntity(entity: DETAILS): void {
    this.entity = entity;

    if (!this.form) {
      this.form = new FormGroup(this.configuration.controlsBuilder(entity));

      if (!this.userService.canUpdate(this.configuration.resource)) {
        this.form.disable();
      }
    } else {
      this.form.patchValue(entity as any);
    }
  }

  private loadState(): void {
    this.configuration.stateFetch.subscribe({
      next: state => {
        this.state = state;
        this.onStateChange.next(state);
      },
      error: error => this.status = {
        type: 'danger',
        title: 'Error loading data!',
        content: error,
      },
      complete: () => this.isLoadingData = false,
    });
  }
}
