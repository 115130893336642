<div class="col-sm-9">
  <ng-container
    *ngTemplateOutlet="input; context: {control: formGroup.controls.street, name: 'street', label: 'Street'}"></ng-container>
</div>
<div class="col-sm-3">
  <ng-container
    *ngTemplateOutlet="input; context: {control: formGroup.controls.houseNumber, name: 'houseNumber', label: 'House number'}"></ng-container>
</div>
<div class="col-sm-6">
  <ng-container
    *ngTemplateOutlet="input; context: {control: formGroup.controls.zip, name: 'zip', label: 'Zip code'}"></ng-container>
</div>
<div class="col-sm-6">
  <ng-container
    *ngTemplateOutlet="input; context: {control: formGroup.controls.city, name: 'city', label: 'City'}"></ng-container>
</div>

<ng-template #input let-control="control" let-label="label" let-name="name">
  <label [htmlFor]="name" class="form-label">{{ label }}</label>
  <input [class.is-invalid]="control.invalid" [formControl]="control" [id]="name" [readonly]="readonly"
         class="form-control" type="text">
  <div class="invalid-feedback">{{ getFieldError(control) }}</div>
</ng-template>
