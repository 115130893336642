import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLinkWithHref } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  NgbAlertModule,
  NgbDropdownModule,
  NgbModalModule,
  NgbPaginationModule,
  NgbPopover,
  NgbTooltip,
  NgbTypeaheadModule,
} from '@ng-bootstrap/ng-bootstrap';
import { KeycloakBearerInterceptor } from 'keycloak-angular';
import { AddressInputComponent } from './component/address-input/address-input.component';
import { AdvisorInputComponent } from './component/advisor-input/advisor-input.component';
import { CompanyInputComponent } from './component/company-input/company-input.component';
import { CrudListPageComponent } from './component/crud-list-page/crud-list-page.component';
import { DetailsPageComponent } from './component/details-page/details-page.component';
import { EntityFormComponent } from './component/entity-form/entity-form.component';
import { EnumSetInputComponent } from './component/enum-set-input/enum-set-input.component';
import { FilterInputComponent } from './component/filter-input/filter-input.component';
import { KeycloakUserDataComponent } from './component/keycloak-user-data/keycloak-user-data.component';
import { KeycloakUserInputComponent } from './component/keycloak-user-input/keycloak-user-input.component';
import { NumberInputComponent } from './component/number-input/number-input.component';
import { PhoneNumberInputComponent } from './component/phone-number-input/phone-number-input.component';
import { RolesInputComponent } from './component/roles-input/roles-input.component';
import { SkeletonLoaderComponent } from './component/skeleton-loader/skeleton-loader.component';
import { StatusBannerComponent } from './component/status-banner/status-banner.component';
import { CustomSelectControlDirective } from './directive/custom-select-control.directive';
import { LinkDirective } from './directive/link.directive';
import { LoadingDirective } from './directive/loading.directive';
import { RepeatDirective } from './directive/repeat.directive';
import { AuthGuard } from './guard/auth.guard';
import { HumanReadablePipe } from './pipe/human-readable.pipe';
import { PersonNamePipe } from './pipe/person-name.pipe';
import { AdvisorsService } from './service/advisors.service';
import { ClientsService } from './service/clients.service';
import { CompaniesService } from './service/companies.service';
import { ExternalIdSearchService } from './service/external-id-search.service';
import { InsuranceGroupService } from './service/insurance-group.service';
import { InsuranceProviderService } from './service/insurance-provider.service';
import { LocalSettingsService } from './service/local-settings.service';
import { PoolService } from './service/pool.service';
import { UserService } from './service/user.service';

@NgModule({
  declarations: [
    PersonNamePipe,
    HumanReadablePipe,
    LoadingDirective,
    SkeletonLoaderComponent,
    RepeatDirective,
    CrudListPageComponent,
    AdvisorInputComponent,
    AddressInputComponent,
    CompanyInputComponent,
    RolesInputComponent,
    KeycloakUserInputComponent,
    KeycloakUserDataComponent,
    NumberInputComponent,
    EnumSetInputComponent,
    CustomSelectControlDirective,
    EntityFormComponent,
    LinkDirective,
    DetailsPageComponent,
    FilterInputComponent,
    StatusBannerComponent,
    PhoneNumberInputComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    RouterLinkWithHref,
    NgbPaginationModule,
    NgbTypeaheadModule,
    NgbModalModule,
    NgbAlertModule,
    NgbDropdownModule,
    NgbTooltip,
    NgbPopover,
  ],
  exports: [
    PersonNamePipe,
    LoadingDirective,
    CustomSelectControlDirective,

    CrudListPageComponent,
    AdvisorInputComponent,
    AddressInputComponent,
    CompanyInputComponent,
    RolesInputComponent,
    KeycloakUserDataComponent,
    KeycloakUserInputComponent,
    NumberInputComponent,
    EnumSetInputComponent,
    EntityFormComponent,
    DetailsPageComponent,
  ],
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        AdvisorsService,
        ClientsService,
        CompaniesService,
        UserService,
        PoolService,
        InsuranceProviderService,
        InsuranceGroupService,
        LocalSettingsService,
        ExternalIdSearchService,
        AuthGuard,
        {
          provide: HTTP_INTERCEPTORS,
          multi: true,
          useClass: KeycloakBearerInterceptor,
        },
        provideHttpClient(withInterceptorsFromDi()),
      ],
    };
  }
}
