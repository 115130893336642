import { ValidatorFn, Validators } from '@angular/forms';
import { PhoneNumberUtil } from 'google-libphonenumber';

const ZIP_CODE_PATTERN = /^(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})$/;
const INTERNATIONAL_PHONE_NUMBER_PATTERN = /^\+\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

const phoneUtil = PhoneNumberUtil.getInstance();

export const ZIP_CODE_VALIDATOR = Validators.pattern(ZIP_CODE_PATTERN);
export const INTERNATIONAL_PHONE_NUMBER_VALIDATOR = Validators.pattern(INTERNATIONAL_PHONE_NUMBER_PATTERN);
export const PHONE_NUMBER_VALIDATOR: ValidatorFn = control => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parse(control.value))
      ? null
      : {customMessage: 'This field must be a valid phone number'};
  } catch (e: any) {
    return {customMessage: e.message};
  }
};

export const ZIP_CODE_PATTERN_STRING = ZIP_CODE_PATTERN.toString();
export const INTERNATIONAL_PHONE_NUMBER_PATTERN_STRING = INTERNATIONAL_PHONE_NUMBER_PATTERN.toString();
