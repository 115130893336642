import { AbstractControl } from '@angular/forms';
import { INTERNATIONAL_PHONE_NUMBER_PATTERN_STRING, ZIP_CODE_PATTERN_STRING } from './regexValidator';

interface TypedValidationErrors {
  readonly min?: { min: number, actual: number };
  readonly max?: { max: number, actual: number };
  readonly required?: boolean;
  readonly email?: boolean;
  readonly minLength?: { requiredLength: number, actualLength: number };
  readonly maxLength?: { requiredLength: number, actualLength: number };
  readonly pattern?: { requiredPattern: string, actualValue: string };
  readonly customMessage?: string;

  readonly [key: string]: any;
}

export default function getFieldError(field: AbstractControl | null): string | null {
  const errors = field?.errors as TypedValidationErrors | null;
  if (!errors) {
    return null;
  }
  const errorMessages: string[] = [];

  if (errors.min) {
    errorMessages.push(`This field must be greater than or equal to ${errors.min.min}`);
  }

  if (errors.max) {
    errorMessages.push(`This field must be less than or equal to ${errors.max.max}`);
  }

  if (errors.required) {
    errorMessages.push('This field is required');
  }

  if (errors.email) {
    errorMessages.push('This field must be a valid email address');
  }

  if (errors.minLength) {
    errorMessages.push(`This field must be at least ${errors.minLength.requiredLength} characters long`);
  }

  if (errors.maxLength) {
    errorMessages.push(`This field must be at most ${errors.maxLength.requiredLength} characters long`);
  }

  if (errors.pattern) {
    switch (errors.pattern.requiredPattern) {
      case ZIP_CODE_PATTERN_STRING:
        errorMessages.push('This field must be a valid zip code');
        break;
      case INTERNATIONAL_PHONE_NUMBER_PATTERN_STRING:
        errorMessages.push('This field must be a valid international phone number (e.g. e.g. +49 123 456 7890, +1-212-456-7890)');
        break;
      default:
        errorMessages.push(`This field must match the pattern ${errors.pattern.requiredPattern}`);
        break;
    }
  }

  if (errors.customMessage) {
    errorMessages.push(errors.customMessage);
  }

  return errorMessages.join(', ');
}
