import { Injectable } from '@angular/core';
import {
  InsuranceProviderDetailsDto,
  InsuranceProviderDto,
  InsuranceProviderUpdateDto,
} from '../model/control-panel-api';
import { CrudService } from './crud.service';
import { HttpClient } from '@angular/common/http';
import OneTimeRequestCache from '../../utils/OneTimeRequestCache';
import environment from '../../../environments/environment';
import { map, mergeMap, Observable } from 'rxjs';

function normalizeLegalForm(legalForm: string): string {
  return legalForm.toLowerCase().replace(/\W/g, '');
}

function legalFormMatchScore(term: string, value: string): number {
  term = normalizeLegalForm(term);
  value = normalizeLegalForm(value);

  if (value === term) {
    return 0;
  }

  if (value.startsWith(term)) {
    return 1;
  }

  if (value.includes(term)) {
    return 2;
  }

  return 3;
}

@Injectable()
export class InsuranceProviderService extends CrudService<
  InsuranceProviderDto,
  InsuranceProviderDetailsDto,
  InsuranceProviderUpdateDto
> {
  protected readonly basePath = 'insurance/provider';

  private readonly legalFormCache =
    new OneTimeRequestCache(() => this.http.get<string[]>(`${environment.apiEndpoint}/control-panel/insurance/provider/legal-forms`));

  public constructor(http: HttpClient) {
    super(http);
    this.typeaheadLegalForms = this.typeaheadLegalForms.bind(this);
  }

  public getLegalForms(): Observable<string[]> {
    return this.legalFormCache.request();
  }

  public typeaheadLegalForms($text: Observable<string>): Observable<string[]> {
    return $text
      .pipe(mergeMap(term => this.getLegalForms()
        .pipe(map(legalForms => legalForms.sort((a, b) => legalFormMatchScore(term, a) - legalFormMatchScore(term, b))))));
  }

  protected override updateDto(dto: InsuranceProviderDto, details: InsuranceProviderDetailsDto): InsuranceProviderDto {
    return {
      id: details.id,
      name: details.name,
      type: details.type,
      bafinId: details.bafinId,
      mappings: details.mappings.map(({poolId}) => poolId),
    };
  }
}
