import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormControls } from '../../../utils/AbstractGroupInput';
import { OptionValue } from '../../model/utils';
import { faSave, IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { faBackward, faForward } from '@fortawesome/free-solid-svg-icons';
import { EntityPositionData } from '../../service/crud.service';
import { OperatorFunction } from 'rxjs';
import getFieldError from '../../utils/getFieldError';

export interface BaseInput<T extends object> {
  name: keyof T;
}

export interface BaseRowInput<T extends object> extends BaseInput<T> {
  label: string;
  width?: number;
}

export interface StringRowInput<T extends object> extends BaseRowInput<T> {
  type: 'string';
  placeholder?: string;
  typeahead?: OperatorFunction<string, any[]>;
  fieldType?: string; // default: 'text'
}

export interface NumberRowInput<T extends object> extends BaseRowInput<T> {
  type: 'number';
}

export interface BooleanRowInput<T extends object> extends BaseRowInput<T> {
  type: 'boolean';
}

export interface OptionsRowInput<T extends object> extends BaseRowInput<T> {
  type: 'options' | 'optionSet';
  options: OptionValue<any>[];
}

export interface CustomInput<T extends object> extends BaseInput<T> {
  type: 'custom';
  template: TemplateRef<{ $implicit: FormControl; value: T }>;
}

export type InputConfig<T extends object> =
  | StringRowInput<T>
  | NumberRowInput<T>
  | BooleanRowInput<T>
  | OptionsRowInput<T>
  | CustomInput<T>;

export interface ButtonConfig {
  label: string;
  icon: IconDefinition;
  type: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';
  submit?: boolean; // default: false
  enabled: boolean;
}

@Component({
  selector: 'app-entity-form',
  templateUrl: './entity-form.component.html',
})
export class EntityFormComponent<T extends object> {
  protected readonly faBackward = faBackward;
  protected readonly faForward = faForward;
  protected readonly getFieldError = getFieldError;

  @Input()
  public form: FormGroup<FormControls<T>>;

  @Input()
  public inputs: InputConfig<T>[];

  @Input()
  public additionalButtons: ButtonConfig[] = [];

  @Input()
  public positionData: EntityPositionData | null;

  @Output()
  public readonly submit = new EventEmitter<void>();

  protected get hasButtons(): boolean {
    return this.form.enabled || this.additionalButtons.length > 0;
  }

  protected get buttons(): ButtonConfig[] {
    const buttons: ButtonConfig[] = [...this.additionalButtons];
    if (this.form.enabled) {
      buttons.push({
        label: 'Save',
        icon: faSave,
        type: 'primary',
        submit: true,
        enabled: this.form.valid,
      });
    }

    return buttons;
  }

  protected trackInputBy(index: number, input: InputConfig<T>): any {
    return input.name;
  }
}
