@for (banner of banners;track banner.type + banner.title + banner.content) {
  <ngb-alert (closed)="dismiss($index)" [type]="banner.type" [animation]="false"
             class="d-flex flex-row align-items-center gap-1">
    @if (getIcon(banner.type)) {
      <fa-icon [icon]="getIcon(banner.type)!" class="me-2"></fa-icon>
    }
    <div class="w-100">
      <div class="d-flex flex-row">
        <span [class.fw-bold]="banner.content" class="flex-grow-1">{{ banner.title }}</span>
        @if (canCopyErrorData(banner)) {
          <button (click)="copyErrorData(banner)"
                  class="btn btn-sm btn-outline-warning">
            Copy error
          </button>
        }
      </div>

      @if (banner.content) {
        @if (isHttpErrorResponse(banner.content)) {
          <div class="d-flex flex-row">
            @if (banner.content.error && banner.content.status >= 400 && banner.content.status <= 499) {
              <p class="mt-1 mb-0">
                @if (isDeveloper) {
                  <span>{{ banner.content.status }} {{ banner.content.statusText }} - </span>
                }
                {{ banner.content.error }}
              </p>
            } @else {
              @if (isDeveloper) {
                <code class="overflow-auto">
                  @if (!isString(banner.content.error)) {
                    <pre>{{ banner.content.error }}: {{ banner.content.error | json }}</pre>
                  } @else if (expanded.has(banner)) {
                    <pre>{{ banner.content.error }}</pre>
                    <a (click)="expanded.delete(banner)" href="javascript:void(0)">Show less</a>
                  } @else {
                    {{ firstLine(banner.content.error) }}<br>
                    <a (click)="expanded.add(banner)" href="javascript:void(0)">Show more</a>
                  }
                </code>
              } @else {
                <span>Unknown error!</span>
              }
            }
          </div>
        } @else {
          <p class="small mt-1 mb-0">{{ banner.content }}</p>
        }
      }
    </div>
  </ngb-alert>
}
