import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { Observable, Subject } from 'rxjs';
import parseJwtToken from '../../utils/parseJwtToken';
import { LocalSettingsService } from './local-settings.service';

interface AccessToken {
  name: string;
}

@Injectable()
export class UserService {
  private readonly parsedTokenSubject = new Subject<AccessToken>();
  private currentToken: AccessToken | null = null;
  private roles: string[] = [];

  public constructor(private readonly keycloakService: KeycloakService,
                     private readonly localSettingsService: LocalSettingsService,
                     private readonly router: Router) {
    this.updateToken();
    this.keycloakService.keycloakEvents$
      .subscribe(event => {
        if (event.type === KeycloakEventType.OnAuthRefreshSuccess || event.type === KeycloakEventType.OnAuthSuccess || event.type === KeycloakEventType.OnReady) {
          this.updateToken();
        }
      });
  }

  public get canImpersonateClient(): boolean {
    return this.roles.includes('client:impersonate');
  }

  public get canImpersonateAdvisor(): boolean {
    return this.roles.includes('advisor:impersonate');
  }

  public get isDeveloperAllowed(): boolean {
    return this.roles.includes('developer');
  }

  public get isDeveloper(): boolean {
    return this.localSettingsService.developerMode && this.isDeveloperAllowed;
  }

  public getName(): Observable<string> {
    return new Observable<string>(subscriber => {
      if (this.currentToken) {
        subscriber.next(this.currentToken.name);
      }

      this.parsedTokenSubject.subscribe(token => {
        subscriber.next(token.name);
      });
    });
  }

  public logout(): void {
    this.keycloakService.logout(window.location.origin + this.router.url);
  }

  public canRead(resource: string): boolean {
    return this.roles.includes(`${resource}:read`);
  }

  public canUpdate(resource: string): boolean {
    return this.roles.includes(`${resource}:update`);
  }

  public canDelete(resource: string): boolean {
    return this.roles.includes(`${resource}:delete`);
  }

  public canCreate(resource: string): boolean {
    return this.roles.includes(`${resource}:create`);
  }

  public hasRole(role: string): boolean {
    return this.roles.includes(role);
  }

  private updateToken(): void {
    this.roles = this.keycloakService.getUserRoles();
    this.keycloakService.getToken()
      .then(token => {
        const parsedToken = parseJwtToken<AccessToken>(token);
        this.currentToken = parsedToken;
        this.parsedTokenSubject.next(parsedToken);
      });
  }
}
