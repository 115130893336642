import { Pipe, PipeTransform } from '@angular/core';
import { PersonName } from '../model/control-panel-api';

function isPersonName(x: any): x is PersonName {
  if (!x) return false;
  return 'firstName' in x && 'lastName' in x;
}

@Pipe({name: 'personName'})
export class PersonNamePipe implements PipeTransform {
  public transform(value: any): any {
    if (!isPersonName(value)) {
      return '';
    }

    return [value.firstName, value.lastName].filter(Boolean).join(' ');
  }
}
