import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CompanyCreateDto,
  CompanyCreationDto,
  CompanyDetailsDto,
  CompanyDto,
  CompanyUpdateDto,
} from '../model/control-panel-api';
import { QueryRequest } from '../model/page';
import { CrudService } from './crud.service';

@Injectable()
export class CompaniesService extends CrudService<CompanyDto, CompanyDetailsDto, CompanyUpdateDto, QueryRequest, CompanyCreateDto, CompanyCreationDto> {
  protected readonly basePath = 'company';

  public constructor(http: HttpClient) {
    super(http);
  }

  protected override updateDto(dto: CompanyDto, details: CompanyDetailsDto): CompanyDto {
    return {
      id: details.id,
      name: details.name,
      adminName: details.admin.name,
      created: details.created,
    };
  }
}
