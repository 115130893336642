@for (current of configuration;track current.property) {
  @if (filters.has(current.property)) {
    <div class="col-12">
      <h6>{{ current.title }}</h6>
      <div class="d-flex flex-column gap-2">
        @for (filter of filters.get(current.property)!.every;track filterTrackBy(filter)) {
          <div class="bg-light-subtle p-2 rounded-1 d-flex flex-row gap-2">
            <ng-container
              *ngTemplateOutlet="{string, number, boolean, enum, custom}[current.type.type]; context {type: current.type, filter}"></ng-container>
            <button class="btn btn-outline-danger" type="button" (click)="removeFilter(current.property, filter)"
                    ngbTooltip="Delete filter">
              <fa-icon [icon]="faTrash"></fa-icon>
            </button>
          </div>
        }
      </div>
    </div>
  }
}

@if (filters.size === 0) {
  <span class="text-muted">There are no filters added yet.</span>
}

<div class="row g-0 gap-2">
  <button (click)="applyFilters()" class="col btn btn-primary" type="button">
    <fa-icon [icon]="faCheck"></fa-icon>
    Apply
  </button>
  <div class="col-auto btn-group" ngbDropdown>
    <button class="btn btn-success dropdown-toggle" ngbDropdownToggle type="button">
      <fa-icon [icon]="faPlus"></fa-icon>
    </button>
    <ul class="dropdown-menu" ngbDropdownMenu role="menu">
      @for (filter of configuration;track filter.property) {
        <li role="menuitem">
          <button (click)="addFilter(filter)" class="dropdown-item" type="button">{{ filter.title }}</button>
        </li>
      }
    </ul>
  </div>
</div>

<ng-template #string let-filter="filter" let-type="type">
  <select [(ngModel)]="filter.operator" class="form-select">
    <option value="EQUALS">{{ userService.isDeveloper ? 'Equals' : 'Is' }}</option>
    <option value="CONTAINS">Contains</option>
    <option value="STARTS_WITH">Starts with</option>
    <option value="ENDS_WITH">Ends with</option>
    @if (type.nullable) {
      <option value="NOT_NULL">{{ userService.isDeveloper ? 'Is not null' : 'Not present' }}</option>
      <option value="NULL">{{ userService.isDeveloper ? 'Is null' : 'Present' }}</option>
    }
  </select>
  <input (ngModelChange)="filter.value = $event"
         [disabled]="filter.operator === 'NOT_NULL' || filter.operator === 'NULL'"
         [ngModel]="filter.operator === 'NOT_NULL' || filter.operator === 'NULL' ? null : filter.value"
         class="form-control"/>
  <button (click)="filter.caseSensitive = !filter.caseSensitive"
          [disabled]="filter.operator === 'NOT_NULL' || filter.operator === 'NULL'"
          [ngbTooltip]="filter.caseSensitive ? 'Case sensitive' : 'Case insensitive'"
          class="btn btn{{filter.caseSensitive ? '' : '-outline'}}-secondary">
    <fa-icon [icon]="faFont"></fa-icon>
  </button>
</ng-template>

<ng-template #number let-filter="filter" let-type="type">
  <select [(ngModel)]="filter.operator" class="form-select">
    <option value="GT">Greater than</option>
    <option value="GTE">Greater than or equals</option>
    <option value="LT">Less than</option>
    <option value="LTE">Less than or equals</option>
    <option value="EQ">{{ userService.isDeveloper ? 'Equals' : 'Is' }}</option>
    <option value="NEQ">{{ userService.isDeveloper ? 'Not equals' : 'Is not' }}</option>
    @if (type.nullable) {
      <option value="NOT_NULL">{{ userService.isDeveloper ? 'Is not null' : 'Not present' }}</option>
      <option value="NULL">{{ userService.isDeveloper ? 'Is null' : 'Present' }}</option>
    }
  </select>
  <input (ngModelChange)="filter.value = $event"
         [disabled]="filter.operator === 'NOT_NULL' || filter.operator === 'NULL'"
         [max]="type.max" [min]="type.min" [ngModel]="filter.operator === 'NOT_NULL' || filter.operator === 'NULL' ? null : filter.value" class="form-control"
         type="number"/>
</ng-template>

<ng-template #boolean let-filter="filter" let-type="type">
  <select [(ngModel)]="filter.value" class="form-select">
    <option [value]="true">Is {{ userService.isDeveloper ? 'true' : 'yes' }}</option>
    <option [value]="false">Is {{ userService.isDeveloper ? 'false' : 'no' }}</option>
    @if (type.nullable) {
      <option [value]="null">{{ userService.isDeveloper ? 'Null' : 'None' }}</option>
    }
  </select>
</ng-template>

<ng-template #enum let-filter="filter" let-type="type">
  <select [(ngModel)]="filter.equals" class="form-select">
    <option [value]="true">{{ userService.isDeveloper ? 'Equals' : 'Is' }}</option>
    <option [value]="false">{{ userService.isDeveloper ? 'Not equals' : 'Is not' }}</option>
  </select>
  <select [(ngModel)]="filter.value" [nullLabel]="type.nullable ? 'None' : null" [options]="type.options"
          class="form-select"></select>
</ng-template>

<ng-template #custom let-filter="filter" let-type="type">
  <ng-container *ngTemplateOutlet="type.template; context {$implicit: filter}"></ng-container>
</ng-template>
