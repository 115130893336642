import { Component, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import AbstractInput from '../../../utils/AbstractInput';
import { OptionValue } from '../../model/utils';

@Component({
  selector: 'app-enum-set-input',
  templateUrl: './enum-set-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: EnumSetInputComponent,
    },
  ],
  host: {
    'class': 'd-flex flex-wrap gap-3',
  },
})
export class EnumSetInputComponent<T> extends AbstractInput<T[]> {
  @Input()
  public values: OptionValue<T>[];

  public constructor() {
    super();
  }

  protected toggle(value: T): void {
    if (this.value.includes(value)) {
      this.value = this.value.filter(v => v !== value);
    } else {
      this.value = [...this.value, value];
    }
    this.markAsTouched();
  }
}
