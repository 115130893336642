@for (item of items;track $index) {
  <div [className]="buildClasses(item)" [ngStyle]="{width: item.width, height: item.height}">
    @switch (item.type) {
      @case ('breadcrumb') {
        <span *appRepeat="item.count ?? 1" class="breadcrumb-item"></span>
      }
      @case ('form') {
        <div *appRepeat="item.count ?? 1" class="mb-3 row">
          <div class="col-sm-2"></div>
          <div class="col-sm-10"></div>
        </div>
      }
      @case ('table') {
        <ng-container *ngTemplateOutlet="table; context {$implicit: item}"></ng-container>
      }
    }
  </div>
}

<ng-template #table let-item>
  <div class="table-responsive text-nowrap">
    <table class="table table-striped">
      <ng-container *ngTemplateOutlet="item.tableHeader"></ng-container>
      <tbody>
        <tr *appRepeat="item.count ?? 1">
          <td *appRepeat="item.columnCount"></td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>
