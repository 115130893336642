import { QueryRequest } from '../model/page';
import { CrudService, IdEntity } from './crud.service';
import { Observable } from 'rxjs';
import { RoleDto } from '../model/control-panel-api';
import OneTimeRequestCache from '../../utils/OneTimeRequestCache';
import environment from '../../../environments/environment';
import buildFormData from '../../utils/buildFormData';
import { HttpClient } from '@angular/common/http';
import { KeycloakService } from 'keycloak-angular';

export abstract class CrudUserService<
  DTO extends IdEntity,
  DETAILS extends IdEntity,
  UPDATE,
  QUERY extends QueryRequest = QueryRequest,
  CREATE = UPDATE,
  CREATE_RESPONSE = DETAILS
> extends CrudService<DTO, DETAILS, UPDATE, QUERY, CREATE, CREATE_RESPONSE> {
  private readonly rolesCache = new OneTimeRequestCache<RoleDto[]>(() =>
    this.http.get<RoleDto[]>(`${environment.apiEndpoint}/control-panel/${this.basePath}/role`),
  );

  protected abstract readonly impersonationRedirectUri: string;

  protected constructor(http: HttpClient, private readonly keycloakService: KeycloakService) {
    super(http);
  }

  public logoutEverywhere(keycloakId: string): Observable<void> {
    return this.http.delete<void>(
      `${environment.apiEndpoint}/control-panel/${this.basePath}/${keycloakId}/logout`,
    );
  }

  public getRoles(): Observable<RoleDto[]> {
    return this.rolesCache.request();
  }

  public impersonate(id: number): void {
    this.keycloakService.getToken().then((token) =>
      open(
        `${environment.apiEndpoint}/control-panel/${
          this.basePath
        }/${id}/impersonate?${buildFormData({
          redirectUri: this.impersonationRedirectUri,
          token,
        })}`,
        '_blank',
      ),
    );
  }
}
