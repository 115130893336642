import { Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';

export class RepeatContext {
  constructor(public readonly index: number, public count: number) {
  }

  get first(): boolean {
    return this.index === 0;
  }

  get last(): boolean {
    return this.index === this.count - 1;
  }

  get even(): boolean {
    return this.index % 2 === 0;
  }

  get odd(): boolean {
    return !this.even;
  }
}

@Directive({
  selector: '[appRepeat]',
})
export class RepeatDirective {
  private _lastCount = 0;

  @Input('appRepeat')
  public set count(count: number) {
    if (this._lastCount === count) return;

    if (this._lastCount > count) {
      for (let i = count; i < this._lastCount; i++) {
        this.viewContainer.remove(i);
      }
    } else if (this._lastCount < count) {
      for (let i = 0; i < count; i++) {
        const oldView = this.viewContainer.get(i);
        if (oldView) {
          (oldView as EmbeddedViewRef<RepeatContext>).context.count = count;
        } else {
          this.viewContainer.createEmbeddedView(this.templateRef, new RepeatContext(i, count), i);
        }
      }
    }
  }

  public constructor(private readonly templateRef: TemplateRef<any>,
                     private readonly viewContainer: ViewContainerRef) {
  }
}
