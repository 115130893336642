import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LocalSettingsService } from './shared/service/local-settings.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly darkModeQuery = window.matchMedia('(prefers-color-scheme: dark)');
  private themeSubscription: Subscription | null = null;

  public constructor(private readonly localSettingsService: LocalSettingsService) {
    this.autoUpdateTheme = this.autoUpdateTheme.bind(this);
    this.updateTheme = this.updateTheme.bind(this);
  }

  public ngOnInit(): void {
    this.updateTheme();
    this.themeSubscription = this.localSettingsService.themeSubject.subscribe(this.updateTheme);
  }

  public ngOnDestroy(): void {
    this.darkModeQuery.removeEventListener('change', this.autoUpdateTheme);
    this.themeSubscription?.unsubscribe();
  }

  private updateTheme(): void {
    switch (this.localSettingsService.theme) {
      case 'auto':
        this.darkModeQuery.addEventListener('change', this.autoUpdateTheme);
        this.autoUpdateTheme();
        break;
      case 'light':
        this.darkModeQuery.removeEventListener('change', this.autoUpdateTheme);
        this.setTheme('light');
        break;
      case 'dark':
        this.darkModeQuery.removeEventListener('change', this.autoUpdateTheme);
        this.setTheme('dark');
        break;
    }
  }

  private autoUpdateTheme(): void {
    this.setTheme(this.darkModeQuery.matches ? 'dark' : 'light');
  }

  private setTheme(theme: 'light' | 'dark'): void {
    document.body.setAttribute('data-bs-theme', theme);
  }
}
