<div class="container-xxl">
  <ol class="breadcrumb my-4">
    @for (path of breadcrumbPath;track path) {
      <li class="breadcrumb-item">{{ path }}</li>
    }
    <li class="breadcrumb-item active">{{ entityNamePlural }}</li>
  </ol>

  <app-status-banner [status]="status"></app-status-banner>

  <div class="card mb-3">
    <div class="card-body">
      <div class="row mb-4 mx-2">
        <input (change)="debouncedLoadEntities.flush()"
               (input)="debouncedLoadEntities()" [(ngModel)]="request.query" [placeholder]="searchPlaceholder"
               class="form-control inline-block" type="search">
      </div>
      <div class="row flex-md-row flex-column gap-md-0 gap-3">
        <div class="col d-flex flex-row align-items-center gap-1">
          <span>Show </span>
          <select (change)="loadEntitiesNow()" [(ngModel)]="request.pageSize" class="form-select form-control-sm"
                  style="width: 92px">
            <option [value]="25">25</option>
            <option [value]="50">50</option>
            <option [value]="75">75</option>
            <option [value]="100">100</option>
          </select>
          <span> results per page</span>
        </div>
        <div class="col d-flex align-items-center justify-content-md-center">
          <span *appLoading="!response; item {type: 'block', width: '150px', height: '24px'}"
                class="fw-bold">Found {{ response?.total ?? '?' }} {{ entityNamePlural }}</span>
        </div>
        <div class="col d-flex justify-content-md-end gap-2">
          @if (createRoute) {
            <button [link]="createRoute" class="btn btn-primary">
              <fa-icon [icon]="faPlus"></fa-icon>
              Create
            </button>
          }
          <button (click)="modal.open(configDialog, {size: filterConfiguration ? 'xl' : 'sm'})" class="btn border-0"
                  ngbTooltip="Configuration">
            <fa-icon [icon]="faGear"></fa-icon>
          </button>
        </div>
      </div>
    </div>

    <div
      *appLoading="!response; item {type: 'table', count: request.pageSize, tableHeader: thead, columnCount: columns.length}"
      class="table-responsive text-nowrap">
      <table [class.table-hover]="!!detailsRoute" class="table table-striped">
        <ng-container *ngTemplateOutlet="thead"></ng-container>
        <tbody class="table-border-bottom-0">
          @for (entity of response?.data ?? [];track trackByEntity(entity)) {
            <tr [link]="detailsRoute?.(entity)" preserveQueryParams>
              @for (column of displayColumns;track column.property) {
                <td [class.font-italic]="isDisabled(entity)"
                    [class.text-muted]="isDisabled(entity)" style="vertical-align: middle">
                  @if (column.template) {
                    <ng-container [ngTemplateOutletContext]="{ $implicit: entity, column }"
                                  [ngTemplateOutlet]="column.template!"></ng-container>
                  } @else {
                    <span [class.font-italic]="isDisabled(entity)"
                          [class.text-muted]="isDisabled(entity)">{{ formatColumn(entity, column) }}</span>
                  }
                </td>
              }

              <td style="width: 27px">
                <div class="d-flex justify-content-end">
                  <button [link]="detailsRoute?.(entity)" class="btn btn-sm btn-outline-secondary" openInNewTab
                          preserveQueryParams
                          style="--bs-btn-padding-x: 0.45rem;--bs-btn-padding-y: 0.15rem">
                    <fa-icon [icon]="faArrowUpRightFromSquare"></fa-icon>
                  </button>
                </div>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>

    <ng-template #thead>
      <thead>
        <tr>
          @for (column of displayColumns;track column.property) {
            <th (click)="(column.sortable ?? true) && sortBy(column.property)"
                [tabIndex]="column.sortable ?? true ? 0 : undefined">
              <span>{{ column.title }}</span>
              @if (column.sortable ?? true) {
                <fa-icon [icon]="getSortIcon(column.property)" class="float-end"></fa-icon>
              }
            </th>
          }

          <th></th>
        </tr>
      </thead>
    </ng-template>

    <div *appLoading="!response; item {type: 'block'}" class="card-body">
      <ngb-pagination [(page)]="currentPage" [collectionSize]="response?.total ?? 0" [maxSize]="10"
                      [pageSize]="request.pageSize"
                      [rotate]="false"></ngb-pagination>
    </div>
  </div>
</div>

<ng-template #configDialog let-close="close">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Configuration</h4>
  </div>
  <div class="modal-body row container-lg">
    @if (filterConfiguration) {
      <div class="col-md-8">
        <h5 class="mb-3">Filters</h5>
        <app-filter-input [configuration]="filterConfiguration!" [value]="request.filter"
                          (apply)="applyFilter($event); close()"></app-filter-input>
      </div>
    }

    <div [class]="filterConfiguration ? 'col-md-4' : 'col-md-12'">
      <h5 class="mb-3">Columns</h5>

      @for (property of configuration.order;track property) {
        <div>
          <label class="p-1 my-2 border rounded d-flex flex-row">
            <span class="flex-grow-1">{{ getColumnTitle(property) }}</span>
            <input (input)="setVisibleState(property, $event)" [checked]="configuration.visible[property]"
                   type="checkbox"/>
          </label>
        </div>
      }
    </div>
  </div>
</ng-template>
