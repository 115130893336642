export interface CountryCode {
  readonly countryCode: string;
  readonly callingCode: number;
  readonly countryName: string;
}

export function getCountryCode(callingCode: number): CountryCode {
  return COUNTRY_CODES.find((countryCode) => countryCode.callingCode === callingCode)!;
}

export function toFlagEmoji(countryCode: string): string {
  return countryCode
    .replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 0x1f1a5));
}

const COUNTRY_CODES: readonly CountryCode[] = [
  {countryCode: 'DE', callingCode: 49, countryName: 'Germany'},
  {countryCode: 'AF', callingCode: 93, countryName: 'Afghanistan'},
  {countryCode: 'AL', callingCode: 355, countryName: 'Albania'},
  {countryCode: 'DZ', callingCode: 213, countryName: 'Algeria'},
  {countryCode: 'AD', callingCode: 376, countryName: 'Andorra'},
  {countryCode: 'AO', callingCode: 244, countryName: 'Angola'},
  {countryCode: 'AR', callingCode: 54, countryName: 'Argentina'},
  {countryCode: 'AM', callingCode: 374, countryName: 'Armenia'},
  {countryCode: 'AW', callingCode: 297, countryName: 'Aruba'},
  {countryCode: 'AC', callingCode: 247, countryName: 'Ascension Island'},
  {countryCode: 'AU', callingCode: 61, countryName: 'Australia'},
  {countryCode: 'AT', callingCode: 43, countryName: 'Austria'},
  {countryCode: 'AZ', callingCode: 994, countryName: 'Azerbaijan'},
  {countryCode: 'BH', callingCode: 973, countryName: 'Bahrain'},
  {countryCode: 'BD', callingCode: 880, countryName: 'Bangladesh'},
  {countryCode: 'BY', callingCode: 375, countryName: 'Belarus'},
  {countryCode: 'BE', callingCode: 32, countryName: 'Belgium'},
  {countryCode: 'BZ', callingCode: 501, countryName: 'Belize'},
  {countryCode: 'BJ', callingCode: 229, countryName: 'Benin'},
  {countryCode: 'BT', callingCode: 975, countryName: 'Bhutan'},
  {countryCode: 'BO', callingCode: 591, countryName: 'Bolivia'},
  {countryCode: 'BA', callingCode: 387, countryName: 'Bosnia & Herzegovina'},
  {countryCode: 'BW', callingCode: 267, countryName: 'Botswana'},
  {countryCode: 'BR', callingCode: 55, countryName: 'Brazil'},
  {countryCode: 'IO', callingCode: 246, countryName: 'British Indian Ocean Territory'},
  {countryCode: 'BN', callingCode: 673, countryName: 'Brunei'},
  {countryCode: 'BG', callingCode: 359, countryName: 'Bulgaria'},
  {countryCode: 'BF', callingCode: 226, countryName: 'Burkina Faso'},
  {countryCode: 'BI', callingCode: 257, countryName: 'Burundi'},
  {countryCode: 'KH', callingCode: 855, countryName: 'Cambodia'},
  {countryCode: 'CM', callingCode: 237, countryName: 'Cameroon'},
  {countryCode: 'CV', callingCode: 238, countryName: 'Cape Verde'},
  {countryCode: 'BQ', callingCode: 599, countryName: 'Caribbean Netherlands / Curaçao'},
  {countryCode: 'CF', callingCode: 236, countryName: 'Central African Republic'},
  {countryCode: 'TD', callingCode: 235, countryName: 'Chad'},
  {countryCode: 'CL', callingCode: 56, countryName: 'Chile'},
  {countryCode: 'CN', callingCode: 86, countryName: 'China'},
  {countryCode: 'CO', callingCode: 57, countryName: 'Colombia'},
  {countryCode: 'KM', callingCode: 269, countryName: 'Comoros'},
  {countryCode: 'CG', callingCode: 242, countryName: 'Congo – Brazzaville'},
  {countryCode: 'CD', callingCode: 243, countryName: 'Congo – Kinshasa'},
  {countryCode: 'CK', callingCode: 682, countryName: 'Cook Islands'},
  {countryCode: 'CR', callingCode: 506, countryName: 'Costa Rica'},
  {countryCode: 'CI', callingCode: 225, countryName: 'Côte D’Ivoire'},
  {countryCode: 'HR', callingCode: 385, countryName: 'Croatia'},
  {countryCode: 'CU', callingCode: 53, countryName: 'Cuba'},
  {countryCode: 'CY', callingCode: 357, countryName: 'Cyprus'},
  {countryCode: 'CZ', callingCode: 420, countryName: 'Czechia'},
  {countryCode: 'DK', callingCode: 45, countryName: 'Denmark'},
  {countryCode: 'DJ', callingCode: 253, countryName: 'Djibouti'},
  {countryCode: 'EC', callingCode: 593, countryName: 'Ecuador'},
  {countryCode: 'EG', callingCode: 20, countryName: 'Egypt'},
  {countryCode: 'SV', callingCode: 503, countryName: 'El Salvador'},
  {countryCode: 'GQ', callingCode: 240, countryName: 'Equatorial Guinea'},
  {countryCode: 'ER', callingCode: 291, countryName: 'Eritrea'},
  {countryCode: 'EE', callingCode: 372, countryName: 'Estonia'},
  {countryCode: 'SZ', callingCode: 268, countryName: 'Eswatini'},
  {countryCode: 'ET', callingCode: 251, countryName: 'Ethiopia'},
  {countryCode: 'FO', callingCode: 298, countryName: 'Faroe Islands'},
  {countryCode: 'FJ', callingCode: 679, countryName: 'Fiji'},
  {countryCode: 'FI', callingCode: 358, countryName: 'Finland'},
  {countryCode: 'FR', callingCode: 33, countryName: 'France'},
  {countryCode: 'PF', callingCode: 689, countryName: 'French Polynesia'},
  {countryCode: 'GA', callingCode: 241, countryName: 'Gabon'},
  {countryCode: 'GM', callingCode: 220, countryName: 'Gambia'},
  {countryCode: 'GE', callingCode: 995, countryName: 'Georgia'},
  {countryCode: 'GH', callingCode: 233, countryName: 'Ghana'},
  {countryCode: 'GI', callingCode: 350, countryName: 'Gibraltar'},
  {countryCode: 'GR', callingCode: 30, countryName: 'Greece'},
  {countryCode: 'GL', callingCode: 299, countryName: 'Greenland'},
  {countryCode: 'GP', callingCode: 590, countryName: 'Guadeloupe'},
  {countryCode: 'GT', callingCode: 502, countryName: 'Guatemala'},
  {countryCode: 'GW', callingCode: 245, countryName: 'Guinea-Bissau'},
  {countryCode: 'GN', callingCode: 224, countryName: 'Guinea'},
  {countryCode: 'GY', callingCode: 592, countryName: 'Guyana'},
  {countryCode: 'HT', callingCode: 509, countryName: 'Haiti'},
  {countryCode: 'HN', callingCode: 504, countryName: 'Honduras'},
  {countryCode: 'HK', callingCode: 852, countryName: 'Hong Kong SAR China'},
  {countryCode: 'HU', callingCode: 36, countryName: 'Hungary'},
  {countryCode: 'IS', callingCode: 354, countryName: 'Iceland'},
  {countryCode: 'IN', callingCode: 91, countryName: 'India'},
  {countryCode: 'ID', callingCode: 62, countryName: 'Indonesia'},
  {countryCode: 'IR', callingCode: 98, countryName: 'Iran'},
  {countryCode: 'IQ', callingCode: 964, countryName: 'Iraq'},
  {countryCode: 'IE', callingCode: 353, countryName: 'Ireland'},
  {countryCode: 'IL', callingCode: 972, countryName: 'Israel'},
  {countryCode: 'IT', callingCode: 39, countryName: 'Italy'},
  {countryCode: 'JP', callingCode: 81, countryName: 'Japan'},
  {countryCode: 'JO', callingCode: 962, countryName: 'Jordan'},
  {countryCode: 'KE', callingCode: 254, countryName: 'Kenya'},
  {countryCode: 'KI', callingCode: 686, countryName: 'Kiribati'},
  {countryCode: 'XK', callingCode: 383, countryName: 'Kosovo'},
  {countryCode: 'KW', callingCode: 965, countryName: 'Kuwait'},
  {countryCode: 'KG', callingCode: 996, countryName: 'Kyrgyzstan'},
  {countryCode: 'LA', callingCode: 856, countryName: 'Laos'},
  {countryCode: 'LV', callingCode: 371, countryName: 'Latvia'},
  {countryCode: 'LB', callingCode: 961, countryName: 'Lebanon'},
  {countryCode: 'LS', callingCode: 266, countryName: 'Lesotho'},
  {countryCode: 'LR', callingCode: 231, countryName: 'Liberia'},
  {countryCode: 'LY', callingCode: 218, countryName: 'Libya'},
  {countryCode: 'LI', callingCode: 423, countryName: 'Liechtenstein'},
  {countryCode: 'LT', callingCode: 370, countryName: 'Lithuania'},
  {countryCode: 'LU', callingCode: 352, countryName: 'Luxembourg'},
  {countryCode: 'MO', callingCode: 853, countryName: 'Macao Sar China'},
  {countryCode: 'MG', callingCode: 261, countryName: 'Madagascar'},
  {countryCode: 'MW', callingCode: 265, countryName: 'Malawi'},
  {countryCode: 'MY', callingCode: 60, countryName: 'Malaysia'},
  {countryCode: 'MV', callingCode: 960, countryName: 'Maldives'},
  {countryCode: 'ML', callingCode: 223, countryName: 'Mali'},
  {countryCode: 'MT', callingCode: 356, countryName: 'Malta'},
  {countryCode: 'MH', callingCode: 692, countryName: 'Marshall Islands'},
  {countryCode: 'MQ', callingCode: 596, countryName: 'Martinique'},
  {countryCode: 'MR', callingCode: 222, countryName: 'Mauritania'},
  {countryCode: 'MU', callingCode: 230, countryName: 'Mauritius'},
  {countryCode: 'YT', callingCode: 262, countryName: 'Mayotte'},
  {countryCode: 'MX', callingCode: 52, countryName: 'Mexico'},
  {countryCode: 'FM', callingCode: 691, countryName: 'Micronesia'},
  {countryCode: 'MD', callingCode: 373, countryName: 'Moldova'},
  {countryCode: 'MC', callingCode: 377, countryName: 'Monaco'},
  {countryCode: 'MN', callingCode: 976, countryName: 'Mongolia'},
  {countryCode: 'ME', callingCode: 382, countryName: 'Montenegro'},
  {countryCode: 'MA', callingCode: 212, countryName: 'Morocco'},
  {countryCode: 'MZ', callingCode: 258, countryName: 'Mozambique'},
  {countryCode: 'MM', callingCode: 95, countryName: 'Myanmar (Burma)'},
  {countryCode: 'NA', callingCode: 264, countryName: 'Namibia'},
  {countryCode: 'NR', callingCode: 674, countryName: 'Nauru'},
  {countryCode: 'NP', callingCode: 977, countryName: 'Nepal'},
  {countryCode: 'NL', callingCode: 31, countryName: 'Netherlands'},
  {countryCode: 'NC', callingCode: 687, countryName: 'New Caledonia'},
  {countryCode: 'NZ', callingCode: 64, countryName: 'New Zealand'},
  {countryCode: 'NI', callingCode: 505, countryName: 'Nicaragua'},
  {countryCode: 'NE', callingCode: 227, countryName: 'Niger'},
  {countryCode: 'NG', callingCode: 234, countryName: 'Nigeria'},
  {countryCode: 'NU', callingCode: 683, countryName: 'Niue'},
  {countryCode: 'NF', callingCode: 672, countryName: 'Norfolk Island'},
  {countryCode: 'KP', callingCode: 850, countryName: 'North Korea'},
  {countryCode: 'MK', callingCode: 389, countryName: 'North Macedonia'},
  {countryCode: 'NO', callingCode: 47, countryName: 'Norway'},
  {countryCode: 'OM', callingCode: 968, countryName: 'Oman'},
  {countryCode: 'PK', callingCode: 92, countryName: 'Pakistan'},
  {countryCode: 'PW', callingCode: 680, countryName: 'Palau'},
  {countryCode: 'PS', callingCode: 970, countryName: 'Palestinian Territories'},
  {countryCode: 'PA', callingCode: 507, countryName: 'Panama'},
  {countryCode: 'PG', callingCode: 675, countryName: 'Papua New Guinea'},
  {countryCode: 'PY', callingCode: 595, countryName: 'Paraguay'},
  {countryCode: 'PE', callingCode: 51, countryName: 'Peru'},
  {countryCode: 'PH', callingCode: 63, countryName: 'Philippines'},
  {countryCode: 'PL', callingCode: 48, countryName: 'Poland'},
  {countryCode: 'PT', callingCode: 351, countryName: 'Portugal'},
  {countryCode: 'QA', callingCode: 974, countryName: 'Qatar'},
  {countryCode: 'RO', callingCode: 40, countryName: 'Romania'},
  {countryCode: 'RU', callingCode: 7, countryName: 'Russia'},
  {countryCode: 'RW', callingCode: 250, countryName: 'Rwanda'},
  {countryCode: 'WS', callingCode: 685, countryName: 'Samoa'},
  {countryCode: 'SM', callingCode: 378, countryName: 'San Marino'},
  {countryCode: 'ST', callingCode: 239, countryName: 'São Tomé & Príncipe'},
  {countryCode: 'SA', callingCode: 966, countryName: 'Saudi Arabia'},
  {countryCode: 'SN', callingCode: 221, countryName: 'Senegal'},
  {countryCode: 'RS', callingCode: 381, countryName: 'Serbia'},
  {countryCode: 'SC', callingCode: 248, countryName: 'Seychelles'},
  {countryCode: 'SL', callingCode: 232, countryName: 'Sierra Leone'},
  {countryCode: 'SG', callingCode: 65, countryName: 'Singapore'},
  {countryCode: 'SK', callingCode: 421, countryName: 'Slovakia'},
  {countryCode: 'SI', callingCode: 386, countryName: 'Slovenia'},
  {countryCode: 'SB', callingCode: 677, countryName: 'Solomon Islands'},
  {countryCode: 'SO', callingCode: 252, countryName: 'Somalia'},
  {countryCode: 'ZA', callingCode: 27, countryName: 'South Africa'},
  {countryCode: 'GS', callingCode: 500, countryName: 'South Georgia & South Sandwich Islands'},
  {countryCode: 'KR', callingCode: 82, countryName: 'South Korea'},
  {countryCode: 'SS', callingCode: 211, countryName: 'South Sudan'},
  {countryCode: 'ES', callingCode: 34, countryName: 'Spain'},
  {countryCode: 'LK', callingCode: 94, countryName: 'Sri Lanka'},
  {countryCode: 'SH', callingCode: 290, countryName: 'St. Helena'},
  {countryCode: 'PM', callingCode: 508, countryName: 'St. Pierre & Miquelon'},
  {countryCode: 'SD', callingCode: 249, countryName: 'Sudan'},
  {countryCode: 'SR', callingCode: 597, countryName: 'Suriname'},
  {countryCode: 'SE', callingCode: 46, countryName: 'Sweden'},
  {countryCode: 'CH', callingCode: 41, countryName: 'Switzerland'},
  {countryCode: 'SY', callingCode: 963, countryName: 'Syria'},
  {countryCode: 'TW', callingCode: 886, countryName: 'Taiwan'},
  {countryCode: 'TJ', callingCode: 992, countryName: 'Tajikistan'},
  {countryCode: 'TZ', callingCode: 255, countryName: 'Tanzania'},
  {countryCode: 'TH', callingCode: 66, countryName: 'Thailand'},
  {countryCode: 'TL', callingCode: 670, countryName: 'Timor-Leste'},
  {countryCode: 'TG', callingCode: 228, countryName: 'Togo'},
  {countryCode: 'TK', callingCode: 690, countryName: 'Tokelau'},
  {countryCode: 'TO', callingCode: 676, countryName: 'Tonga'},
  {countryCode: 'TN', callingCode: 216, countryName: 'Tunisia'},
  {countryCode: 'TR', callingCode: 90, countryName: 'Turkey'},
  {countryCode: 'TM', callingCode: 993, countryName: 'Turkmenistan'},
  {countryCode: 'TV', callingCode: 688, countryName: 'Tuvalu'},
  {countryCode: 'UG', callingCode: 256, countryName: 'Uganda'},
  {countryCode: 'UA', callingCode: 380, countryName: 'Ukraine'},
  {countryCode: 'AE', callingCode: 971, countryName: 'United Arab Emirates'},
  {countryCode: 'GB', callingCode: 44, countryName: 'United Kingdom'},
  {countryCode: 'US', callingCode: 1, countryName: 'United States / Canada'},
  {countryCode: 'UY', callingCode: 598, countryName: 'Uruguay'},
  {countryCode: 'UZ', callingCode: 998, countryName: 'Uzbekistan'},
  {countryCode: 'VU', callingCode: 678, countryName: 'Vanuatu'},
  {countryCode: 'VA', callingCode: 379, countryName: 'Vatican City'},
  {countryCode: 'VE', callingCode: 58, countryName: 'Venezuela'},
  {countryCode: 'VN', callingCode: 84, countryName: 'Vietnam'},
  {countryCode: 'WF', callingCode: 681, countryName: 'Wallis & Futuna'},
  {countryCode: 'YE', callingCode: 967, countryName: 'Yemen'},
  {countryCode: 'ZM', callingCode: 260, countryName: 'Zambia'},
  {countryCode: 'ZW', callingCode: 263, countryName: 'Zimbabwe'},
];
export default COUNTRY_CODES;
