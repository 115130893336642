import { ParamMap } from '@angular/router';

export interface PageResponse<T> {
  page: number;
  pageSize: number;
  total: number;
  totalPages: number;
  data: T[];
}

export interface PageRequest {
  page: number;
  pageSize: number;
  sortBy?: string;
  ascendingSorting?: boolean;
}

export interface QueryRequest extends PageRequest {
  query?: string;
  filter?: string;
}

function parseBoolean(value: string): boolean {
  return value === 'true';
}

export function extractQueryRequest(params: ParamMap): QueryRequest {
  return {
    pageSize: getParam('page_size', 25, parseInt),
    page: getParam('page', 0, parseInt),
    sortBy: getParam('sort_by', ''),
    ascendingSorting: getParam('ascending_sorting', true, parseBoolean),
    query: getParam('query', ''),
    filter: getParam('filter', undefined, JSON.parse),
  };

  function getParam<T = string>(name: string, fallback: T, parser?: (value: string) => T): T {
    if (!params.has(name)) return fallback;
    if (parser) return parser(params.get(name)!);
    return params.get(name)! as unknown as T;
  }
}
