import { Component, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdvisorsService } from '../../service/advisors.service';
import { faEdit, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { AdvisorRefDto } from '../../model/control-panel-api';
import { debounce } from 'lodash';

const noop = () => {
};

@Component({
  selector: 'app-advisor-input',
  templateUrl: './advisor-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: AdvisorInputComponent,
    },
  ],
  host: {
    class: 'd-block',
  },
})
export class AdvisorInputComponent implements OnDestroy, ControlValueAccessor {
  public readonly faTrashCan = faTrashCan;
  public readonly faEdit = faEdit;

  @Input()
  public nullable = true;

  @Input()
  public companyId: number | null = null;

  @Input()
  public readonly = false;

  public value: AdvisorRefDto | null;
  public advisors: AdvisorRefDto[] = [];
  public query = '';
  public readonly debouncedLoadAdvisors = debounce(this.loadAdvisors, 500);

  private loadedQuery: string | null = null;
  private onChange: (value: AdvisorRefDto | null) => void = noop;
  private onTouched: () => void = noop;
  private touched = false;
  protected disabled = false;

  @ViewChild('modal')
  private readonly modal: TemplateRef<any>;

  public constructor(private readonly advisorsService: AdvisorsService,
                     private readonly modalService: NgbModal) {
  }

  public ngOnDestroy(): void {
    this.debouncedLoadAdvisors.cancel();
  }

  public openModal(): void {
    this.loadAdvisors();
    this.modalService.open(this.modal, {})
      .result
      .then((result: AdvisorRefDto | null | undefined) => {
        if (result === undefined) return;
        this.value = result;
        this.markAsTouched();
        this.onChange(result);
      }, () => {
        // ignore when dismissed
      });
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(obj: any): void {
    this.value = obj;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private loadAdvisors(): void {
    const query = this.query;
    if (this.loadedQuery === query) return;
    this.advisorsService.query({
      query,
      page: 0,
      pageSize: 25,
      companyId: this.companyId ?? undefined,
    })
      .subscribe(advisors => {
        this.advisors = advisors.data;
        this.loadedQuery = query;
      });
  }

  private markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
