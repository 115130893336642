import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export type Theme = 'auto' | 'light' | 'dark';

function asTheme(value: string | null): Theme {
  switch (value) {
    case 'auto':
    case 'light':
    case 'dark':
      return value;
    default:
      return 'auto';
  }
}

@Injectable({providedIn: 'root'})
export class LocalSettingsService {
  private themeValue: Theme = asTheme(localStorage.getItem('theme'));
  private developerModeValue: boolean = localStorage.getItem('developMode') !== 'false';

  public readonly themeSubject = new Subject<Theme>();
  public readonly developerModeSubject = new Subject<boolean>();

  public get theme(): Theme {
    return this.themeValue;
  }

  public set theme(value: Theme) {
    this.themeValue = value;
    localStorage.setItem('theme', value);
    this.themeSubject.next(value);
  }

  public get developerMode(): boolean {
    return this.developerModeValue;
  }

  public set developerMode(value: boolean) {
    this.developerModeValue = value;
    localStorage.setItem('developMode', String(value));
    this.developerModeSubject.next(value);
  }
}
