import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PoolDto } from '../model/control-panel-api';
import environment from '../../../environments/environment';
import OneTimeRequestCache from '../../utils/OneTimeRequestCache';

@Injectable()
export class PoolService {
  private readonly poolsCache =
    new OneTimeRequestCache(() => this.http.get<readonly PoolDto[]>(`${environment.apiEndpoint}/control-panel/insurance/pool`));

  public constructor(private readonly http: HttpClient) {
  }

  public getPools(): Observable<readonly PoolDto[]> {
    return this.poolsCache.request();
  }
}
