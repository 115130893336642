import { Directive, Input } from '@angular/core';
import { ActivatedRoute, Params, QueryParamsHandling, Router } from '@angular/router';

@Directive({
  selector: '[link]',
  host: {
    '(click)': 'handleEvent($event)',
    '(auxclick)': 'handleEvent($event)',
    '(keydown)': 'handleEvent($event)',
    '[tabindex]': 'link ? 0 : null',
  },
})
export class LinkDirective {
  @Input()
  public link: any[] | null | undefined = null;

  @Input()
  public openInNewTab: boolean | string = false;

  @Input()
  public preserveQueryParams: boolean | string = false;

  @Input()
  public queryParams: Params | null = null;

  private get queryParamsHandling(): QueryParamsHandling | null {
    if (this.preserveQueryParams !== false) {
      return 'merge';
    }

    return null;
  }

  public constructor(private readonly router: Router,
                     private readonly activatedRoute: ActivatedRoute) {
  }

  protected handleEvent(event: Event): void {
    if (!this.link) {
      return;
    }

    event.stopPropagation();

    if (event instanceof KeyboardEvent && event.key !== 'Enter') {
      return;
    }

    if (this.shouldOpenInNewTab(event)) {
      const urlTree = this.router.createUrlTree(this.link, {
        relativeTo: this.activatedRoute,
        queryParamsHandling: this.queryParamsHandling,
        queryParams: this.queryParams,
      });
      window.open(urlTree.toString(), '_blank');
    } else {
      this.router.navigate(this.link, {
        relativeTo: this.activatedRoute,
        queryParamsHandling: this.queryParamsHandling,
        queryParams: this.queryParams,
      });
    }
  }

  private shouldOpenInNewTab(event: Event): boolean {
    if (this.openInNewTab !== false) {
      return true;
    }

    if (event instanceof KeyboardEvent) {
      return event.ctrlKey || event.shiftKey || event.metaKey;
    }

    if (event instanceof MouseEvent) {
      return event.button === 1 || event.ctrlKey || event.shiftKey || event.metaKey;
    }

    return false;
  }
}
