<div>
  <ng-container
    *ngTemplateOutlet="input; context: {control: formGroup.controls.email, name: 'email', label: 'E-Mail'}"></ng-container>
</div>
<div class="col-sm-6">
  <ng-container
    *ngTemplateOutlet="input; context: {control: formGroup.controls.firstName, name: 'firstName', label: 'First name'}"></ng-container>
</div>
<div class="col-sm-6">
  <ng-container
    *ngTemplateOutlet="input; context: {control: formGroup.controls.lastName, name: 'lastName', label: 'Last name'}"></ng-container>
</div>
<div>
  <label class="col-sm-2 col-form-label" for="phoneNumber">Mobile</label>
  <app-phone-number-input [control]="formGroup.controls.phoneNumber" [readonly]="readonly"
                          name="phoneNumber"></app-phone-number-input>
</div>
<div>
  <div class="form-check form-check-inline form-switch" popoverTitle="Enabled"
       ngbPopover="This setting enables or disables the user account. When an account is disabled, the user cannot login, use forget password or register with that email again. A disabled account is blocking the E-Mail address from being used entierly."
       triggers="mouseenter:mouseleave">
    <input (click)="!readonly || $event.preventDefault()" [attr.aria-readonly]="readonly"
           [formControl]="formGroup.controls.enabled" class="form-check-input" type="checkbox" id="enabled">
    <label class="form-check-label" for="enabled">Enabled</label>
  </div>

  <div class="form-check form-check-inline form-switch" popoverTitle="E-Mail verified"
       ngbPopover="This setting marks the E-Mail address as verified. When an E-Mail address is not verified, the user can login but must verify the E-Mail address before using the application."
       triggers="mouseenter:mouseleave">
    <input (click)="!readonly || $event.preventDefault()" [attr.aria-readonly]="readonly"
           [formControl]="formGroup.controls.emailVerified" class="form-check-input" type="checkbox" id="emailVerified">
    <label class="form-check-label" for="emailVerified">E-Mail verified</label>
  </div>
</div>
<div>
  <label class="col-sm-2 col-form-label" popoverTitle="Permissions"
         ngbPopover="This setting defines the Keycloak roles which are assigned to the user."
         triggers="mouseenter:mouseleave">Permissions</label>
  <div class="col-sm-10 d-flex flex-row align-items-center">
    <app-roles-input [formControl]="formGroup.controls.roles" [readonly]="readonly" [roles]="roles"></app-roles-input>
  </div>
</div>

<ng-template #input let-control="control" let-label="label" let-name="name">
  <label [htmlFor]="name" class="form-label">{{ label }}</label>
  <input [class.is-invalid]="control.invalid" [formControl]="control" [id]="name" [readonly]="readonly"
         class="form-control" type="text">
  <div class="invalid-feedback">{{ getFieldError(control) }}</div>
</ng-template>
