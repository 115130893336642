import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowUpRightFromSquare,
  faGear,
  faPlus,
  faSort,
  faSortDown,
  faSortUp,
} from '@fortawesome/free-solid-svg-icons';
import { debounce } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { extractQueryRequest, PageResponse, QueryRequest } from '../../model/page';
import hashCode from '../../../utils/hashCode';
import { FilterConfiguration } from '../filter-input/filter-input.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StatusInfo } from '../status-banner/status-banner.component';

export interface ColumnDefinition<T> {
  readonly property: string;
  readonly title: string;
  readonly sortable?: boolean;
  readonly format?: (entity: T) => string;
  readonly template?: TemplateRef<{ $implicit: T, column: ColumnDefinition<T> }>;
  readonly defaultVisible?: boolean;
}

interface TableConfiguration {
  visible: Record<string, boolean>;
  order: string[];
}

@Component({
  selector: 'app-crud-list-page',
  templateUrl: './crud-list-page.component.html',
})
export class CrudListPageComponent<T> implements OnInit, OnDestroy {
  protected readonly faPlus = faPlus;
  protected readonly faGear = faGear;
  protected readonly faArrowUpRightFromSquare = faArrowUpRightFromSquare;

  @Input()
  public name: string;

  @Input()
  public loadFunction: (request: QueryRequest) => Observable<PageResponse<T>>;

  @Input()
  public entityNamePlural: string;

  @Input()
  public searchPlaceholder: string;

  @Input()
  public columns: readonly ColumnDefinition<T>[];

  @Input()
  public detailsRoute: ((entity: T) => any[]) | null = null;

  @Input()
  public createRoute: any[] | null = null;

  @Input()
  public enabledColumn: keyof T | null = null;

  @Input()
  public breadcrumbPath: readonly string[] = [];

  @Input()
  public filterConfiguration: readonly FilterConfiguration[] | null = null;

  protected status: StatusInfo | null = null;

  protected get displayColumns(): readonly ColumnDefinition<T>[] {
    return this.configuration.order
      .filter(c => this.configuration.visible[c])
      .map(c => this.columns.find(col => col.property === c)!);
  }

  protected request: QueryRequest = {
    pageSize: 25,
    page: 0,
    sortBy: 'name',
    ascendingSorting: true,
  };
  protected response: PageResponse<T> | null = null;
  protected isLoading = true;

  private currentLoadedHash: number = 0;
  protected configuration: TableConfiguration;

  protected readonly debouncedLoadEntities = debounce(this.loadEntities, 500);

  protected get currentPage(): number {
    return this.request.page + 1;
  }

  protected set currentPage(value: number) {
    this.request.page = value - 1;
    this.loadEntities();
  }

  private routeSubscription: Subscription | null = null;

  public constructor(protected readonly router: Router,
                     protected readonly activatedRoute: ActivatedRoute,
                     protected readonly modal: NgbModal) {
  }

  public ngOnInit(): void {
    try {
      this.configuration = JSON.parse(localStorage.getItem(this.name)!);

      const knownColumns = new Set(this.columns.map(c => c.property));
      this.configuration.order = this.configuration.order.filter(c => knownColumns.has(c));
      this.configuration.visible = Object.fromEntries(Object.entries(this.configuration.visible)
        .filter(([k]) => knownColumns.has(k)));
    } catch {
      this.configuration = {
        visible: {},
        order: [],
      };
    }

    for (let i = 0; i < this.columns.length; i++) {
      const property = this.columns[i].property;
      if (!(property in this.configuration.visible)) {
        this.configuration.visible[property] = this.columns[i].defaultVisible ?? true;
      }

      if (!this.configuration.order.includes(property)) {
        if (i === 0) {
          this.configuration.order.unshift(property);
        } else {
          const previousProperty = this.columns[i - 1].property;
          const previousIndex = this.configuration.order.indexOf(previousProperty);
          this.configuration.order.splice(previousIndex + 1, 0, property);
        }
      }
    }

    this.storeConfiguration();

    this.routeSubscription = this.activatedRoute.queryParamMap.subscribe(queryParam => {
      this.request = extractQueryRequest(queryParam);
      this.loadEntitiesNow();
    });
  }

  public ngOnDestroy(): void {
    this.debouncedLoadEntities.cancel();
    this.routeSubscription?.unsubscribe();
  }

  protected loadEntitiesNow(): void {
    this.debouncedLoadEntities();
    this.debouncedLoadEntities.flush();
  }

  protected sortBy(name: string): void {
    if (this.request.sortBy === name) {
      this.request.ascendingSorting = !this.request.ascendingSorting;
    } else {
      this.request.sortBy = name;
      this.request.ascendingSorting = true;
    }
    this.request.page = 0;
    this.loadEntitiesNow();
  }

  protected getSortIcon(column: string): IconProp {
    if (this.request.sortBy !== column) return faSort;
    return this.request.ascendingSorting ? faSortUp : faSortDown;
  }

  protected formatColumn(entity: T, column: ColumnDefinition<T>): string {
    if (column.format) return column.format(entity);
    return (entity as any)[column.property];
  }

  protected isDisabled(entity: T): boolean {
    if (!this.enabledColumn) {
      return false;
    }

    return !entity[this.enabledColumn];
  }

  protected setVisibleState(property: string, event: Event): void {
    this.configuration.visible[property] = (event.target as HTMLInputElement).checked;
    this.storeConfiguration();
  }

  protected getColumnTitle(property: string): string {
    return this.columns.find(col => col.property === property)!.title;
  }

  protected applyFilter(filter: string | undefined): void {
    this.request.filter = filter;
    this.request.page = 0;
    this.loadEntitiesNow();
  }

  protected trackByEntity(entity: T): string | number {
    return (entity as any).id;
  }

  private loadEntities(): void {
    const newHash = hashCode(this.request);
    if (newHash === this.currentLoadedHash) return;
    this.currentLoadedHash = newHash;

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        page_size: this.request.pageSize,
        page: this.request.page,
        sort_by: this.request.sortBy,
        ascending_sorting: this.request.ascendingSorting,
        query: this.request.query,
        filter: this.request.filter ? JSON.stringify(this.request.filter) : undefined,
      },
      queryParamsHandling: 'merge',
    });

    this.isLoading = true;
    this.loadFunction(this.request).subscribe({
      next: response => {
        this.response = response;
        this.isLoading = false;
      },
      error: error => this.status = {
        type: 'danger',
        title: 'Failed to load entities',
        content: error,
      },
    });
  }

  private storeConfiguration(): void {
    localStorage.setItem(this.name, JSON.stringify(this.configuration));
  }
}
