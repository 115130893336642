import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { PhoneNumber, PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { Subscription } from 'rxjs';
import getFieldError from '../../utils/getFieldError';
import COUNTRY_CODES, { CountryCode, getCountryCode, toFlagEmoji } from './country-codes';

const phoneUtil = PhoneNumberUtil.getInstance();

@Component({
  selector: 'app-phone-number-input',
  templateUrl: './phone-number-input.component.html',
  styleUrls: ['./phone-number-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'input-group has-validation',
  },
})
export class PhoneNumberInputComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input()
  public name: string | null;
  @Input()
  public control: FormControl<string>;
  @Input()
  public readonly: boolean;

  protected readonly getFieldError = getFieldError;
  protected readonly toFlagEmoji = toFlagEmoji;
  protected readonly COUNTRY_CODES = COUNTRY_CODES;
  protected readonly faPhone = faPhone;

  @ViewChild('input')
  protected input: ElementRef<HTMLInputElement>;

  @ViewChild('countryCodeInput')
  protected countryCodeInput: ElementRef<HTMLInputElement>;

  protected countryCode: CountryCode;

  private parsedPhoneNumber: PhoneNumber | null;
  private changeSubscription: Subscription | null;
  private focused: boolean = false;
  private lastControlValue: string | null = null;

  public constructor() {
    this.onControlChanged = this.onControlChanged.bind(this);
  }

  protected get countryCodeEmoji(): string {
    return toFlagEmoji(this.countryCode.countryCode);
  }

  protected get canCall(): boolean {
    return !!this.parsedPhoneNumber;
  }

  private get constructedValue(): string {
    return `+${this.countryCode.callingCode}${this.input.nativeElement.value}`;
  }

  public ngOnInit(): void {
    if (this.control != null) {
      this.changeSubscription = this.control.valueChanges.subscribe(this.onControlChanged);
      this.parseNumberFromControl();
    }
  }

  public ngAfterViewInit(): void {
    this.input.nativeElement.addEventListener('focus', () => this.focused = true);
    this.input.nativeElement.addEventListener('blur', () => {
      this.focused = false;
      try {
        this.parsedPhoneNumber = phoneUtil.parse(this.constructedValue);
        this.updateInputField();
      } catch {
        this.parsedPhoneNumber = null;
      }
      this.updateControlValue();
    });

    this.countryCodeInput.nativeElement.value = this.countryCode.callingCode.toString();
    this.updateInputField();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.input) return;

    if ('control' in changes) {
      this.changeSubscription?.unsubscribe();
      this.changeSubscription = this.control.valueChanges.subscribe(this.onControlChanged);
      this.parseNumberFromControl();
    }
  }

  public ngOnDestroy(): void {
    this.changeSubscription?.unsubscribe();
  }

  protected updateCountryCode(event: Event): void {
    const newCallingCode = parseInt((event.target as HTMLSelectElement).value);
    this.countryCode = getCountryCode(newCallingCode);
    this.parsedPhoneNumber?.setCountryCode(newCallingCode);
    this.updateControlValue();
  }

  protected call(): void {
    if (this.parsedPhoneNumber) {
      open(phoneUtil.format(this.parsedPhoneNumber, PhoneNumberFormat.RFC3966));
    }
  }

  private parseNumberFromControl(): void {
    const value = this.control.value;
    try {
      this.parsedPhoneNumber = phoneUtil.parse(value);
      this.countryCode = getCountryCode(this.parsedPhoneNumber.getCountryCodeOrDefault());
    } catch {
      this.countryCode = COUNTRY_CODES[0];
    }

    if (this.countryCodeInput) {
      this.countryCodeInput.nativeElement.value = this.countryCode.callingCode.toString();
    }

    this.lastControlValue = value;
    this.updateInputField();
  }

  private updateControlValue(): void {
    let value: string;
    if (this.parsedPhoneNumber) {
      value = phoneUtil.format(this.parsedPhoneNumber, PhoneNumberFormat.E164);
    } else {
      value = this.constructedValue;
    }
    this.lastControlValue = value;
    this.control.setValue(value);
    this.updateInputField();
  }

  private onControlChanged(value: string): void {
    if (value !== this.lastControlValue) {
      this.parseNumberFromControl();
    }
  }

  private updateInputField(): void {
    if (this.input && this.parsedPhoneNumber) {
      if (this.focused) {
        this.input.nativeElement.value = this.parsedPhoneNumber.getNationalNumber()?.toString() ?? '';
      } else {
        const nationalNumber = phoneUtil.format(this.parsedPhoneNumber, PhoneNumberFormat.NATIONAL);
        this.input.nativeElement.value = nationalNumber.startsWith('0') ? nationalNumber.substring(1) : nationalNumber;
      }
    }
  }
}
