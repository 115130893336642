import { ControlValueAccessor } from '@angular/forms';

const noop = () => {
};

export default class AbstractInput<T> implements ControlValueAccessor {
  protected get value(): T {
    return this._value;
  }

  protected set value(value: T) {
    this._value = value;
    this.onChange(value);
  }

  private _value: T;

  protected onChange: (value: T) => void = noop;
  protected onTouched: () => void = noop;
  protected disabled = false;

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(obj: T): void {
    this._value = obj;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  protected markAsTouched(): void {
    this.onTouched();
  }
}
