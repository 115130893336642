import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import {
  BooleanFilterDto,
  EnumFilterDto,
  FilterCompositionDto,
  NumberFilterDto,
  StringFilterDto,
} from '../../model/control-panel-api';
import { OptionValue } from '../../model/utils';
import { faCheck, faFont, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { UserService } from '../../service/user.service';
import { OperatorFunction } from 'rxjs';

type Filter = StringFilterDto | NumberFilterDto | BooleanFilterDto | EnumFilterDto<any> | any;

export interface StringFilterType {
  readonly type: 'string';
  readonly typeahead?: OperatorFunction<string, any[]>;
  readonly nullable?: boolean;
  readonly defaultFilter?: () => StringFilterDto;
}

export interface NumberFilterType {
  readonly type: 'number';
  readonly min?: number;
  readonly max?: number;
  readonly nullable?: boolean;
  readonly defaultFilter?: () => NumberFilterDto;
}

export interface BooleanFilterType {
  readonly type: 'boolean';
  readonly nullable?: boolean;
  readonly defaultFilter?: () => BooleanFilterDto;
}

export interface EnumFilterType<T> {
  readonly type: 'enum';
  readonly options: readonly OptionValue<T>[];
  readonly nullable?: boolean;
  readonly defaultFilter?: () => EnumFilterDto<T>;
}

export interface CustomFilterType<F> {
  readonly type: 'custom';
  readonly template: TemplateRef<{ $implicit: F }>;
  readonly defaultFilter: () => F;
}

export type FilterType =
  | StringFilterType
  | NumberFilterType
  | BooleanFilterType
  | EnumFilterType<any>
  | CustomFilterType<any>;

export interface FilterConfiguration {
  readonly property: string;
  readonly title: string;
  readonly type: FilterType;
}

function isFilterEmpty(filter: FilterCompositionDto<Filter, any>): boolean {
  return !filter.every?.length && !filter.some?.length && !filter.none?.length;
}

function getDefaultFilter(filterType: FilterType): Filter {
  if (filterType.defaultFilter) {
    return filterType.defaultFilter();
  }

  switch (filterType.type) {
    case 'string':
      return {value: '', operator: 'STARTS_WITH', caseSensitive: false};
    case 'number':
      return {value: 0, operator: 'EQ'};
    case 'boolean':
      return {value: false};
    case 'enum':
      return {value: filterType.options[0].value, equals: true};
  }
}

const filterTrackers = new WeakMap<Filter, symbol>();

@Component({
  selector: 'app-filter-input',
  templateUrl: './filter-input.component.html',
  host: {
    class: 'd-flex flex-column gap-4',
  },
})
export class FilterInputComponent {
  protected readonly faPlus = faPlus;
  protected readonly faCheck = faCheck;
  protected readonly faTrash = faTrash;
  protected readonly faFont = faFont;

  @Input()
  public configuration: readonly FilterConfiguration[];

  @Input()
  public set value(value: string | undefined) {
    if (value) {
      this.filters = new Map(Object.entries(JSON.parse(value)));
    } else {
      this.filters.clear();
    }
  }

  @Output()
  public readonly apply = new EventEmitter<string | undefined>();

  protected filters = new Map<string, FilterCompositionDto<Filter, any>>();

  public constructor(protected readonly userService: UserService) {
  }

  protected addFilter(configuration: FilterConfiguration): void {
    if (!this.filters.has(configuration.property)) {
      this.filters.set(configuration.property, {every: [], some: [], none: []});
    }

    this.filters.get(configuration.property)!.every!.push(getDefaultFilter(configuration.type));
  }

  protected applyFilters(): void {
    if (this.filters.size === 0) {
      this.apply.emit();
      return;
    }

    this.apply.emit(JSON.stringify(Object.fromEntries(this.filters), (key, value) => {
      if (typeof value === 'object') {
        if (value === null) {
          return null;
        }

        if (Array.isArray(value)) {
          if (value.length) {
            return value;
          }

          return undefined;
        }

        if (Object.keys(value).length) {
          return value;
        }

        return undefined;
      }

      return value;
    }));
  }

  protected filterTrackBy(filter: Filter): symbol {
    if (!filterTrackers.has(filter)) {
      filterTrackers.set(filter, Symbol());
    }

    return filterTrackers.get(filter)!;
  }

  protected removeFilter(property: string, filter: Filter): void {
    const composition = this.filters.get(property)!;
    const index = composition.every!.indexOf(filter);
    if (index !== -1) {
      composition.every!.splice(index, 1);
    }

    if (isFilterEmpty(composition)) {
      this.filters.delete(property);
    }
  }
}
