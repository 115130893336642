import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, map, Observable } from 'rxjs';
import OneTimeRequestCache from '../../utils/OneTimeRequestCache';
import environment from '../../../environments/environment';

export interface ExternalIdSearchResult {
  readonly id: string;
  readonly name: string;
}

@Injectable()
export class ExternalIdSearchService {
  private readonly providerCaches = new Map<number, OneTimeRequestCache<readonly ExternalIdSearchResult[]>>();
  private readonly typesCaches = new Map<number, OneTimeRequestCache<readonly ExternalIdSearchResult[]>>();

  public constructor(private readonly http: HttpClient) {
  }

  public bulkGetProviderExternalIds(poolIds: readonly number[]): Observable<Map<number, readonly ExternalIdSearchResult[]>> {
    return forkJoin(poolIds.map(poolId => this.getProviderExternalIds(poolId)))
      .pipe(map(results => new Map(poolIds.map((poolId, index) => [poolId, results[index]]))));
  }

  public bulkGetTypeExternalIds(poolIds: readonly number[]): Observable<Map<number, readonly ExternalIdSearchResult[]>> {
    return forkJoin(poolIds.map(poolId => this.getTypeExternalIds(poolId)))
      .pipe(map(results => new Map(poolIds.map((poolId, index) => [poolId, results[index]]))));
  }

  public getProviderExternalIds(poolId: number): Observable<readonly ExternalIdSearchResult[]> {
    if (!this.providerCaches.has(poolId)) {
      this.providerCaches.set(poolId, new OneTimeRequestCache(() =>
        this.http.get<readonly ExternalIdSearchResult[]>(`${environment.apiEndpoint}/pool/insurance/providers/${poolId}`)));
    }

    return this.providerCaches.get(poolId)!.request();
  }

  public getTypeExternalIds(poolId: number): Observable<readonly ExternalIdSearchResult[]> {
    if (!this.typesCaches.has(poolId)) {
      this.typesCaches.set(poolId, new OneTimeRequestCache(() =>
        this.http.get<readonly ExternalIdSearchResult[]>(`${environment.apiEndpoint}/pool/insurance/types/${poolId}`)));
    }

    return this.typesCaches.get(poolId)!.request();
  }
}
