import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import environment from '../environments/environment';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared/guard/auth.guard';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule.forRoot(),
    RouterModule.forRoot([
      {
        path: '',
        loadChildren: () => import('./layout/layout.module').then((m) => m.LayoutModule),
        canActivate: [AuthGuard],
      },
    ], {
      useHash: false,
      enableTracing: false,
      scrollPositionRestoration: 'enabled',
    }),
  ],
  providers: [
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'de-DE',
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (keycloak: KeycloakService) => () => keycloak.init({
        config: environment.auth,
        initOptions: {
          onLoad: 'check-sso',
          silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
          enableLogging: true,
          checkLoginIframe: false,
        },
        enableBearerInterceptor: true,
      }),
      multi: true,
      deps: [KeycloakService],
    },
    KeycloakService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
