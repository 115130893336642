import { Environment } from './environment.types';

export default function createEnvironment(
  apiEndpoint: string,
  b2cPortalUrl: string,
  b2bPortalUrl: string,
  production: boolean = false,
  authBaseUrl: string = apiEndpoint,
): Environment {
  return {
    production,
    apiEndpoint,
    b2cPortalUrl,
    b2bPortalUrl,
    auth: {
      url: `${authBaseUrl}/auth`,
      clientId: 'webapp',
      realm: 'control-panel',
    },
  };
}
