import { from, Observable, tap } from 'rxjs';
import Synchronize from './Synchronize';

export default class OneTimeRequestCache<T> {
  private cache: T | null = null;
  private synchronize = new Synchronize();

  public constructor(private readonly requestBuilder: () => Observable<T>) {
  }

  public request(): Observable<T> {
    if (this.cache) {
      return from([this.cache!]);
    }

    return this.synchronize.runSync<T>(() => {
      if (this.cache) {
        return from([this.cache!]);
      }

      return this.requestBuilder()
        .pipe(tap(value => this.cache = value));
    });
  }
}
