import { Component, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import AbstractInput from '../../../utils/AbstractInput';
import { RoleDto } from '../../model/control-panel-api';

@Component({
  selector: 'app-roles-input',
  templateUrl: './roles-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: RolesInputComponent,
    },
  ],
})
export class RolesInputComponent extends AbstractInput<string[]> {
  @Input()
  public roles: readonly RoleDto[];

  @Input()
  public readonly = false;

  public handleChange(event: Event, id: string): void {
    const newValue = (event.target as HTMLInputElement).checked;
    const currentValue = this.value.includes(id);

    if (newValue === currentValue) {
      return;
    }

    if (newValue) {
      this.value = [...this.value, id];
    } else {
      this.value = this.value.filter(roleId => roleId !== id);
    }

    this.markAsTouched();
  }
}
