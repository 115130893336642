import { ComponentRef, Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { SkeletonLoaderComponent, SkeletonLoaderItem } from '../component/skeleton-loader/skeleton-loader.component';

@Directive({
  selector: '[appLoading]',
})
export class LoadingDirective {
  private contentRef: EmbeddedViewRef<void> | null = null;
  private loaderRef: ComponentRef<SkeletonLoaderComponent> | null = null;

  public constructor(private readonly templateRef: TemplateRef<any>,
                     private readonly viewContainer: ViewContainerRef) {
  }

  @Input('appLoading')
  public set isLoading(loading: boolean) {
    this.viewContainer.clear();

    if (loading) {
      this.contentRef = null;
      if (!this.loaderRef) {
        this.loaderRef = this.viewContainer.createComponent(SkeletonLoaderComponent);
        this.loaderRef.setInput('items', this._items);
      }
    } else {
      this.loaderRef = null;
      if (!this.contentRef) {
        this.contentRef = this.viewContainer.createEmbeddedView(this.templateRef);
      }
    }
  }

  private _items: SkeletonLoaderItem[] = [];

  @Input('appLoadingItems')
  public set items(items: SkeletonLoaderItem[]) {
    this._items = items;
    this.loaderRef?.setInput('items', items);
  }

  @Input('appLoadingItem')
  public set item(item: SkeletonLoaderItem) {
    this.items = [item];
  }
}
