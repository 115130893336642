<div class="col-sm-6">
  <label class="form-label" for="lastLogin">Last login</label>

  @if (value.lastLogin) {
    <input [value]="value.lastLogin | date: 'medium'" class="form-control" disabled id="lastLogin" type="text">
  } @else {
    <i class="d-block">never</i>
  }
</div>
<div class="col-sm-6">
  <label class="form-label" for="created">Created</label>
  <input [value]="value.created | date: 'medium'" class="form-control" disabled id="created" type="text">
</div>

@if (userService.isDeveloper) {
  <div>
    <label class="form-label" for="keycloakId">Keycloak ID</label>
    <div class="input-group">
      <input [value]="value.id" class="form-control" disabled id="keycloakId" type="text">
      <button (click)="copy(value.id)" class="btn btn-outline-primary" type="button">
        <fa-icon [icon]="faCopy"></fa-icon>
      </button>
    </div>
  </div>
}
