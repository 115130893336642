import { Observable, Subject } from 'rxjs';

export default class Synchronize {
  private locked = false;
  private queue: Subject<void>[] = [];

  public get isLocked(): boolean {
    return this.locked;
  }

  public runSync<T>(task: () => Observable<T>): Observable<T> {
    return new Observable<T>(subscriber => {
      this.waitUntilUnlocked()
        .subscribe(() => {
          task()
            .subscribe({
              next: value => subscriber.next(value),
              error: error => subscriber.error(error),
              complete: () => {
                subscriber.complete();
                this.locked = false;
                this.queue.shift()?.next();
              },
            })
        });
    });
  }

  private waitUntilUnlocked(): Observable<void> {
    if (!this.locked) {
      this.locked = true;
      return new Observable<void>(subscriber => subscriber.next());
    }

    const subject = new Subject<void>();
    this.queue.push(subject);
    return subject.asObservable();
  }
}
