@if (value) {
  <b>{{ value.name }}</b>
} @else {
  <i>No company</i>
}

@if (!readonly) {
  <button type="button" (click)="openModal()" class="btn btn-sm btn-outline-primary ms-2">
    <fa-icon [icon]="faEdit"></fa-icon>
  </button>
}

<ng-template #modal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Choose company</h4>
  </div>
  <div class="modal-body">
    <div class="mb-4">
      <label for="advisor-search">Search:</label>
      <input (input)="debouncedLoadCompanies()" (change)="debouncedLoadCompanies.flush()" [(ngModel)]="query"
             class="form-control inline-block" placeholder="Search for name or admin" id="advisor-search" type="search">
    </div>
    @if (companies.length) {
      <div class="table-responsive text-nowrap">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Admin</th>
            </tr>
          </thead>
          <tbody class="table-border-bottom-0">
            @for (company of companies;track company.id) {
              <tr (click)="modal.close(company)" tabindex="-1">
                <td>#{{ company.id }}</td>
                <td>{{ company.name }}</td>
                <td>{{ company.adminName | personName }}</td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    } @else {
      <i>Could not find any companies.</i>
    }

    @if (nullable) {
      <button class="btn btn-danger" (click)="modal.close(null)">
        <fa-icon [icon]="faTrashCan" class="me-1"></fa-icon>
        Remove company
      </button>
    }
  </div>
</ng-template>
