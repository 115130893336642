import { HttpClient } from '@angular/common/http';
import { PageResponse, QueryRequest } from '../model/page';
import { forkJoin, map, merge, mergeMap, Observable, of, tap } from 'rxjs';
import environment from 'src/environments/environment';
import buildFormData from '../../utils/buildFormData';
import KeyedSynchronize from '../../utils/KeyedSynchronize';
import hashCode from '../../utils/hashCode';

export interface IdEntity {
  readonly id: number;
}

export interface EntityPositionData {
  readonly previous: number | null;
  readonly previousPage: number | null;
  readonly next: number | null;
  readonly nextPage: number | null;
}

interface CachedPageResponse extends PageResponse<number> {
  readonly version: number;
}

export abstract class CrudService<
  DTO extends IdEntity,
  DETAILS extends IdEntity,
  UPDATE,
  QUERY extends QueryRequest = QueryRequest,
  CREATE = UPDATE,
  CREATE_RESPONSE = DETAILS
> {
  protected abstract readonly basePath: string;

  private readonly detailsMap = new Map<number, DETAILS>();
  private readonly dtoMap = new Map<number, DTO>();
  private readonly queryMap = new Map<number, CachedPageResponse>();

  private readonly querySynchronize = new KeyedSynchronize<number>();
  private readonly detailsFetchSynchronize = new KeyedSynchronize<number>();

  private currentVersion = 0;

  protected constructor(protected readonly http: HttpClient) {
  }

  public query(request: QUERY): Observable<PageResponse<DTO>> {
    const key = hashCode(request);

    return this.querySynchronize.runSync<PageResponse<DTO>>(key, () => {
      if (this.queryMap.has(key)) {
        const {version, ...idsPage} = this.queryMap.get(key)!;
        const data = idsPage.data.map(id => this.dtoMap.get(id)!);

        if (data.every(Boolean)) {
          const cachedResponse = of({...idsPage, data});
          if (version < this.currentVersion) {
            return merge(cachedResponse, this.performQuery(request, key));
          } else {
            return cachedResponse;
          }
        }
      }

      return this.performQuery(request, key);
    });
  }

  public fetchDetails(id: number): Observable<DETAILS> {
    return this.detailsFetchSynchronize.runSync<DETAILS>(id, () => {
      if (this.detailsMap.has(id)) {
        return of(this.detailsMap.get(id)!);
      }

      return this.http.get<DETAILS>(`${environment.apiEndpoint}/control-panel/${this.basePath}/${id}`)
        .pipe(tap(details => this.detailsMap.set(id, details)));
    });
  }

  public updateEntity(id: number, data: UPDATE): Observable<DETAILS> {
    return this.http.put<DETAILS>(`${environment.apiEndpoint}/control-panel/${this.basePath}/${id}`, data)
      .pipe(tap(details => {
        this.currentVersion++;
        this.detailsMap.set(id, details);
        if (this.dtoMap.has(id)) {
          this.dtoMap.set(id, this.updateDto(this.dtoMap.get(id)!, details));
        }
      }));
  }

  public createEntity(data: CREATE): Observable<CREATE_RESPONSE> {
    return this.http.post<CREATE_RESPONSE>(`${environment.apiEndpoint}/control-panel/${this.basePath}`, data)
      .pipe(tap(() => this.currentVersion++));
  }

  public deleteEntity(id: number): Observable<void> {
    return this.http.delete<void>(`${environment.apiEndpoint}/control-panel/${this.basePath}/${id}`)
      .pipe(tap(() => this.currentVersion++));
  }

  public getEntityPosition(id: number, request: QUERY): Observable<EntityPositionData> {
    return this.query(request)
      .pipe(mergeMap<PageResponse<DTO>, Observable<EntityPositionData>>(currentPage => {
        const currentIndex = currentPage.data.findIndex(entity => entity.id === id);
        if (currentIndex === -1) {
          return of({
            previous: null,
            previousPage: null,
            next: null,
            nextPage: null,
          });
        }

        let previous: Observable<readonly [id: number | null, page: number | null]>;
        let next: Observable<readonly [id: number | null, page: number | null]>;

        if (currentIndex === 0) {
          if (request.page === 0) {
            previous = of([null, null]);
          } else {
            previous = this.query({...request, page: request.page - 1})
              .pipe(mergeMap(previousPage => {
                if (previousPage.data.length === 0) {
                  return of([null, null] as const);
                }

                return of([previousPage.data[previousPage.data.length - 1].id, previousPage.page] as const);
              }));
          }
        } else {
          previous = of([currentPage.data[currentIndex - 1].id, currentPage.page]);
        }

        if (currentIndex === currentPage.data.length - 1) {
          if (request.page === currentPage.totalPages - 1) {
            next = of([null, null]);
          } else {
            next = this.query({...request, page: request.page + 1})
              .pipe(mergeMap(nextPage => {
                if (nextPage.data.length === 0) {
                  return of([null, null] as const);
                }

                return of([nextPage.data[0].id, nextPage.page] as const);
              }));
          }
        } else {
          next = of([currentPage.data[currentIndex + 1].id, currentPage.page]);
        }

        return forkJoin([previous, next])
          .pipe(map(([[previous, previousPage], [next, nextPage]]) =>
            ({previous, previousPage, next, nextPage})));
      }));
  }

  protected abstract updateDto(dto: DTO, details: DETAILS): DTO;

  private performQuery(request: QUERY, key: number): Observable<PageResponse<DTO>> {
    return this.http.get<PageResponse<DTO>>(`${environment.apiEndpoint}/control-panel/${this.basePath}?${buildFormData(request)}`)
      .pipe(tap(response => {
        this.queryMap.set(key, {
          ...response,
          data: response.data.map(entity => entity.id),
          version: this.currentVersion,
        });
        response.data.forEach(entity => this.dtoMap.set(entity.id, entity));
      }));
  }
}
