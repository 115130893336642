import { Component, Input } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { FormControls } from '../../../utils/AbstractGroupInput';
import { Address } from '../../model/control-panel-api';
import getFieldError from '../../utils/getFieldError';
import { ZIP_CODE_VALIDATOR } from '../../utils/regexValidator';

export function validateAddress(control: AbstractControl<Address>): ValidationErrors | null {
  const {street, houseNumber, city, zip} = control.value;
  const values = [street, houseNumber, city, zip];

  if (!values.some(Boolean)) {
    return null; // all values are null or undefined
  }

  if (!values.every(Boolean)) {
    return {incomplete: true};
  }

  if (zip && !/^\d{5}$/.test(zip)) {
    return {invalidZip: true};
  }

  return null;
}

@Component({
  selector: 'app-address-input',
  templateUrl: './address-input.component.html',
  host: {
    class: 'row g-3',
  },
})
export class AddressInputComponent {
  protected readonly getFieldError = getFieldError;

  @Input()
  public formGroup: FormGroup<FormControls<Address>>;

  @Input()
  public readonly = false;

  public static createForm(): FormGroup<FormControls<Address>> {
    return new FormGroup<FormControls<Address>>({
      street: new FormControl('', {nonNullable: true, validators: Validators.required}),
      houseNumber: new FormControl('', {nonNullable: true, validators: Validators.required}),
      zip: new FormControl('', {nonNullable: true, validators: [Validators.required, ZIP_CODE_VALIDATOR]}),
      city: new FormControl('', {nonNullable: true, validators: Validators.required}),
    });
  }
}
