import { Component, Input } from '@angular/core';
import AbstractInput from '../../../utils/AbstractInput';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: NumberInputComponent,
    },
  ],
})
export class NumberInputComponent extends AbstractInput<number | null> {
  @Input()
  public id: string | null;

  @Input()
  public placeholder: string | null;

  @Input()
  public invalid: boolean = false;

  @Input()
  public errors: string | null = null;

  protected get stringValue(): string {
    if (typeof this.value !== 'number') {
      return '';
    }

    return this.value.toString();
  }

  protected set stringValue(value: string) {
    if (value === '') {
      this.value = null;
    } else {
      const parsed = parseInt(value);
      this.value = isNaN(parsed) ? null : parsed;
    }
  }

  public constructor() {
    super();
  }
}
