import { Injectable } from '@angular/core';
import { CrudService } from './crud.service';
import {
  InsuranceCategoryDto,
  InsuranceGroupDetailsDto,
  InsuranceGroupDto,
  InsuranceGroupUpdateDto,
} from '../model/control-panel-api';
import OneTimeRequestCache from '../../utils/OneTimeRequestCache';
import environment from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class InsuranceGroupService extends CrudService<InsuranceGroupDto, InsuranceGroupDetailsDto, InsuranceGroupUpdateDto> {
  protected readonly basePath = 'insurance/group';

  private readonly categoriesCache =
    new OneTimeRequestCache(() => this.http.get<InsuranceCategoryDto[]>(`${environment.apiEndpoint}/control-panel/insurance/group/category`));

  public constructor(http: HttpClient) {
    super(http);
  }

  public getCategories(): Observable<InsuranceCategoryDto[]> {
    return this.categoriesCache.request();
  }

  protected override updateDto(dto: InsuranceGroupDto, details: InsuranceGroupDetailsDto): InsuranceGroupDto {
    return {
      id: details.id,
      name: details.name,
      groupType: details.groupType,
    };
  }
}
