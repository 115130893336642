import { Component, Input } from '@angular/core';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { KeycloakUserDto } from '../../model/control-panel-api';
import { UserService } from '../../service/user.service';

@Component({
  selector: 'app-keycloak-user-data',
  templateUrl: './keycloak-user-data.component.html',
  host: {
    class: 'row g-3',
  },
})
export class KeycloakUserDataComponent {
  public readonly faCopy = faCopy;

  @Input()
  public value: KeycloakUserDto;

  public constructor(public readonly userService: UserService) {
  }

  public copy(text: string): void {
    navigator.clipboard.writeText(text);
  }
}
