<span class="input-group-prepend input-group-text">
  {{ countryCodeEmoji }} +{{ countryCode.callingCode }}
  <select #countryCodeInput (change)="updateCountryCode($event)" [disabled]="readonly" autocomplete="tel-country-code">
    @for (country of COUNTRY_CODES; track country.countryCode) {
      <option [value]="country.callingCode">
        {{ country.countryName }} {{ toFlagEmoji(country.countryCode) }} +{{ country.callingCode }}
      </option>
    }
  </select>
</span>
<input #input [class.is-invalid]="control.invalid" [id]="name"
       [readonly]="readonly" autocomplete="tel-national" class="form-control" placeholder="176 2432233" type="tel">
@if (canCall) {
  <div class="input-group-append input-group-text" ngbTooltip="Call phone number" (click)="call()">
    <fa-icon [icon]="faPhone"></fa-icon>
  </div>
}
<div class="invalid-feedback">{{ getFieldError(control) }}</div>
