import { Component, Input, TemplateRef, ViewEncapsulation } from '@angular/core';

interface BaseSkeletonLoaderItem {
  count?: number;
  classNames?: string;
  width?: string;
  height?: string;
}

export interface BreadcrumbSkeletonLoaderItem extends BaseSkeletonLoaderItem {
  type: 'breadcrumb';
}

export interface FormSkeletonLoaderItem extends BaseSkeletonLoaderItem {
  type: 'form';
}

export interface TableSkeletonLoaderItem extends BaseSkeletonLoaderItem {
  type: 'table';
  tableHeader: TemplateRef<any>;
  columnCount: number;
}

export interface BlockSkeletonLoaderItem extends BaseSkeletonLoaderItem {
  type: 'block';
}

export type SkeletonLoaderItem =
  BreadcrumbSkeletonLoaderItem
  | FormSkeletonLoaderItem
  | TableSkeletonLoaderItem
  | BlockSkeletonLoaderItem;

@Component({
  selector: 'app-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SkeletonLoaderComponent {
  @Input() public items: SkeletonLoaderItem[];

  public buildClasses(item: SkeletonLoaderItem): string {
    const classes = ['skeleton'];
    switch (item.type) {
      case 'breadcrumb':
        classes.push('breadcrumb');
        break;
      case 'form':
        classes.push('skeleton-form');
        break;
      case 'table':
        classes.push('skeleton-table');
        break;
      case 'block':
        classes.push('skeleton-block');
        break;
    }
    if (item.classNames) {
      classes.push(item.classNames);
    }

    return classes.join(' ');
  }
}
