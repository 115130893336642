<form (ngSubmit)="submit.emit()" [formGroup]="form" class="row g-3">
  @for (input of inputs;track input.name) {
    <ng-container
      *ngTemplateOutlet="{string, number, boolean, options, optionSet, custom}[input.type]; context: input"></ng-container>
  }

  @if (hasButtons) {
    <div class="mt-3">
      @for (button of buttons;track button.label) {
        <button [disabled]="!button.enabled" [type]="button.submit ? 'submit' : 'button'"
                class="btn btn-{{button.type}}">
          <fa-icon [icon]="button.icon" class="me-1"></fa-icon>
          {{ button.label }}
        </button>
      }
    </div>
  }

  @if (positionData && (positionData.previous !== null || positionData.next !== null)) {
    <div class="mt-3">
      <button class="btn btn-secondary" [disabled]="positionData.previous === null"
              [link]="['..', positionData.previous]" preserveQueryParams
              [queryParams]="{page: positionData.previousPage}" type="button" ngbTooltip="Go to previous entity">
        <fa-icon [icon]="faBackward"></fa-icon>
      </button>
      <button class="btn btn-secondary float-end" [disabled]="positionData.next === null"
              [link]="['..', positionData.next]" preserveQueryParams [queryParams]="{page: positionData.nextPage}"
              type="button" ngbTooltip="Go to next entity">
        <fa-icon [icon]="faForward"></fa-icon>
      </button>
    </div>
  }

  <ng-template #string let-label="label" let-width="width" let-name="name" let-type="fieldType" let-typeahead="typeahead">
    <div class="col-sm-{{width ?? 12}}">
      <label [htmlFor]="name" class="form-label">{{ label }}</label>
      @if (typeahead) {
        <input [class.is-invalid]="form.get(name)!.invalid" [formControlName]="name" [id]="name" [placeholder]="label"
               [type]="type ?? 'text'" [ngbTypeahead]="typeahead" class="form-control">
      } @else {
        <input [class.is-invalid]="form.get(name)!.invalid"
               [formControlName]="name" [id]="name" [placeholder]="label" [type]="type ?? 'text'"
               class="form-control">
      }
      <div class="invalid-feedback">{{ getFieldError(form.get(name)) }}</div>
    </div>
  </ng-template>
  <ng-template #number let-label="label" let-width="width" let-name="name">
    <div class="col-sm-{{width ?? 12}}">
      <label [htmlFor]="name" class="form-label">{{ label }}</label>
      <app-number-input [errors]="getFieldError(form.get(name))" [formControlName]="name" [id]="name"
                        [invalid]="form.get(name)!.invalid" [placeholder]="label"></app-number-input>
    </div>
  </ng-template>
  <ng-template #boolean let-label="label" let-width="width" let-name="name">
    <div class="col-sm-{{width ?? 12}}">
      <label [htmlFor]="name" class="form-label">{{ label }}</label>
      <div class="col-sm-10 d-flex flex-row align-items-center">
        <input (click)="form.get(name)!.enabled || $event.preventDefault()"
               [attr.aria-readonly]="form.get(name)!.disabled" [attr.indeterminate]="form.get(name)!.value === null"
               [class.is-invalid]="form.get(name)!.invalid" [formControlName]="name" [id]="name"
               class="form-check-input" type="checkbox">
        <div class="invalid-feedback ms-2">{{ getFieldError(form.get(name)) }}</div>
      </div>
    </div>
  </ng-template>
  <ng-template #options let-label="label" let-width="width" let-name="name" let-options="options">
    <div class="col-sm-{{width ?? 12}}">
      <label [htmlFor]="name" class="form-label">{{ label }}</label>
      <select [class.is-invalid]="form.get(name)!.invalid" [formControlName]="name" [id]="name" [options]="options"
              class="form-select"></select>
      <div class="invalid-feedback">{{ getFieldError(form.get(name)) }}</div>
    </div>
  </ng-template>
  <ng-template #optionSet let-label="label" let-width="width" let-name="name" let-options="options">
    <div class="col-sm-{{width ?? 12}}">
      <label [htmlFor]="name" class="form-label">{{ label }}</label>
      <app-enum-set-input [formControlName]="name" [values]="options"></app-enum-set-input>
      <div class="invalid-feedback">{{ getFieldError(form.get(name)) }}</div>
    </div>
  </ng-template>
  <ng-template #custom let-name="name" let-template="template">
    <ng-container *ngTemplateOutlet="template; context: {$implicit: form.get(name), value: form.value}"></ng-container>
  </ng-template>
</form>
