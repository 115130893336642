export default function hashCode(value: any): number {
  switch (typeof value) {
    case 'string': {
      let hash = 0;
      for (let i = 0; i < value.length; i++) {
        hash = ((hash << 5) - hash) + value.charCodeAt(i);
        hash |= 0;
      }
      return hash;
    }
    case 'number':
      return value;
    case 'boolean':
      return value ? 1 : 0;
    case 'function':
    case 'bigint':
    case 'symbol':
      return hashCode(value.toString());
    case 'undefined':
      return 0;
    case 'object':
      if (value === null) {
        return -1;
      }

      if (Array.isArray(value)) {
        return value.reduce((acc, v) => acc ^ hashCode(v), 0);
      } else {
        return Object.entries(value).reduce((acc, [k, v]) => acc ^ hashCode(k) ^ hashCode(v), 0);
      }
  }
}
