import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Observable } from 'rxjs';
import environment from '../../../environments/environment';
import { AdvisorQueryRequest } from '../model/advisor';
import { AdvisorCreationDto, AdvisorDetailsDto, AdvisorDto, AdvisorUpdateDto } from '../model/control-panel-api';
import { CrudUserService } from './user-crud.service';

@Injectable()
export class AdvisorsService extends CrudUserService<
  AdvisorDto,
  AdvisorDetailsDto,
  AdvisorUpdateDto,
  AdvisorQueryRequest,
  AdvisorUpdateDto,
  AdvisorCreationDto
> {
  protected readonly basePath = 'advisor';

  protected readonly impersonationRedirectUri = environment.b2bPortalUrl;

  public constructor(http: HttpClient, keycloakService: KeycloakService) {
    super(http, keycloakService);
  }

  public disableAdvisor(id: number, replacementId: number): Observable<void> {
    return this.http
      .delete<void>(
        `${environment.apiEndpoint}/control-panel/${this.basePath}/${id}?replacement_id=${replacementId}`,
      );
  }

  protected override updateDto(dto: AdvisorDto, details: AdvisorDetailsDto): AdvisorDto {
    return {
      id: details.id,
      name: details.keycloak.name,
      companyName: details.company.name,
      lastLogin: details.keycloak.lastLogin,
      created: details.keycloak.created,
      enabled: details.keycloak.enabled,
    };
  }
}
