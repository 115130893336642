<div class="container-xxl">
  <ng-container
    *ngTemplateOutlet="breadcrumb; context: {loading, value, newEntity: entityId === null}"></ng-container>

  <app-status-banner [status]="status"></app-status-banner>

  <div class="card mb-3">
    <div class="card-body">
      <h5>{{configuration.headline}}</h5>

      <app-entity-form *appLoading="loading; item {type: 'form', count: inputs.length}" (submit)="save()"
                       [form]="form!" [inputs]="inputs" [positionData]="positionData"></app-entity-form>
    </div>
  </div>
</div>
