import { Directive, ElementRef, forwardRef, Input, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { OptionValue } from '../model/utils';

@Directive({
  selector: 'select[options]',
  host: {
    '(change)': 'onChange()',
    '(blur)': 'onTouched()',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomSelectControlDirective),
      multi: true,
    },
  ],
})
export class CustomSelectControlDirective<T> implements ControlValueAccessor {
  private _onChange: (value: any) => void = () => {
  };
  protected onTouched: () => void = () => {
  };

  private _options: readonly OptionValue<T>[] = [];

  @Input()
  public set options(options: readonly OptionValue<T>[]) {
    this._options = options;
    const element = this.elementRef.nativeElement as HTMLSelectElement;
    const children = element.children;

    if (children.length > options.length + 1) {
      for (let i = options.length; i < children.length; i++) {
        this.renderer.removeChild(element, children[i + 1]);
      }
    }

    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      let child = children[i + 1] as HTMLOptionElement;

      if (!child) {
        child = this.renderer.createElement('option');
        this.renderer.appendChild(element, child);
      }

      child.innerText = option.label;
    }
  }

  @Input()
  public set nullLabel(nullLabel: string | null) {
    const element = this.elementRef.nativeElement as HTMLSelectElement;
    const selectOption = element.children.item(0) as HTMLOptionElement;
    if (nullLabel === null) {
      selectOption.innerText = 'Select...';
      selectOption.disabled = true;
    } else {
      selectOption.innerText = nullLabel;
      selectOption.disabled = false;
    }
  }

  public constructor(private readonly renderer: Renderer2,
                     private readonly elementRef: ElementRef) {
    const selectOption = renderer.createElement('option') as HTMLOptionElement;
    selectOption.innerText = 'Select...';
    selectOption.disabled = true;
    selectOption.selected = true;
    renderer.appendChild(elementRef.nativeElement, selectOption);
  }

  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    const element = this.elementRef.nativeElement as HTMLSelectElement;
    element.disabled = isDisabled;
  }

  public writeValue(obj: any): void {
    const index = this._options.findIndex(option => option.value === obj);
    const element = this.elementRef.nativeElement as HTMLSelectElement;
    element.selectedIndex = index !== -1 ? index + 1 : 0;
  }

  protected onChange(): void {
    const element = this.elementRef.nativeElement as HTMLSelectElement;
    const option = this._options[element.selectedIndex - 1];

    if (option) {
      this._onChange(option.value);
    }
  }
}
