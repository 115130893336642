import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { ClientDetailsDto, ClientDto, ClientUpdateDto } from '../model/control-panel-api';
import { CrudUserService } from './user-crud.service';
import environment from '../../../environments/environment';

@Injectable()
export class ClientsService extends CrudUserService<
  ClientDto,
  ClientDetailsDto,
  ClientUpdateDto
> {
  protected readonly basePath = 'client';

  protected readonly impersonationRedirectUri = environment.b2cPortalUrl;

  public constructor(http: HttpClient, keycloakService: KeycloakService) {
    super(http, keycloakService);
  }

  protected override updateDto(dto: ClientDto, details: ClientDetailsDto): ClientDto {
    return {
      id: details.id,
      name: details.keycloak.name,
      advisor: details.advisor,
      enabled: details.keycloak.enabled,
      lastLogin: details.keycloak.lastLogin,
      created: details.keycloak.created,
    };
  }
}
