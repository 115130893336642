import { Component, Input } from '@angular/core';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { faCircleCheck, IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from '../../service/user.service';

type BannerType = 'success' | 'info' | 'warning' | 'danger' | 'primary' | 'secondary' | 'light' | 'dark';

export interface StatusInfo {
  type: BannerType;
  title: string;
  timeout?: number;
  content?: any;
}

@Component({
  selector: 'app-status-banner',
  templateUrl: './status-banner.component.html',
})
export class StatusBannerComponent {
  protected readonly banners: StatusInfo[] = [];
  protected readonly expanded = new WeakSet<StatusInfo>();
  protected readonly isDeveloper: boolean;

  public constructor(userService: UserService) {
    this.isDeveloper = userService.isDeveloper;
  }

  @Input()
  public set status(value: StatusInfo | null) {
    if (value) {
      this.banners.push(value);
      if (value.timeout) {
        setTimeout(() => {
          const index = this.banners.indexOf(value);
          if (index !== -1) {
            this.dismiss(index);
          }
        }, value.timeout);
      }
    } else {
      this.banners.length = 0;
    }
  }

  protected dismiss(index: number): void {
    this.banners.splice(index, 1);
  }

  protected getIcon(type: BannerType): IconDefinition | null {
    switch (type) {
      case 'success':
        return faCircleCheck;
      case 'warning':
      case 'danger':
        return faCircleExclamation;
    }

    return null;
  }

  protected isString(content: any): content is string {
    return typeof content === 'string';
  }

  protected isHttpErrorResponse(content: any): content is HttpErrorResponse {
    return content instanceof HttpErrorResponse;
  }

  protected firstLine(content: string): string {
    return content.split('\n', 1)[0];
  }

  protected copyErrorData(banner: StatusInfo): void {
    const content = banner.content;
    if (this.isHttpErrorResponse(content)) {
      const data = {
        title: banner.title,
        ...content,
        errorString: content.error?.toString(),
      };

      navigator.clipboard.writeText(JSON.stringify(data, null, 2));
    }
  }

  protected canCopyErrorData(banner: StatusInfo): boolean {
    return banner.type === 'danger' && this.isHttpErrorResponse(banner.content);
  }
}
