@if (value) {
  <strong>{{ value.name | personName }}</strong>
  @if (value.companyName) {
    <span> from </span>
    <strong>{{ value.companyName }}</strong>
  } @else {
    <i> (no company)</i>
  }
} @else {
  <i>No advisor assigned</i>
}

@if (!readonly) {
  <button type="button" (click)="openModal()" class="btn btn-sm btn-outline-primary ms-2" [disabled]="disabled">
    <fa-icon [icon]="faEdit"></fa-icon>
  </button>
}

<ng-template #modal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Choose advisor</h4>
  </div>
  <div class="modal-body">
    <div class="mb-4">
      <label for="advisor-search">Search:</label>
      <input (input)="debouncedLoadAdvisors()" (change)="debouncedLoadAdvisors.flush()" [(ngModel)]="query"
             class="form-control inline-block" placeholder="Search for name, company or email" id="advisor-search"
             type="search">
    </div>

    @if (advisors.length) {
      <div class="table-responsive text-nowrap">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Company name</th>
            </tr>
          </thead>
          <tbody class="table-border-bottom-0">
            @for (advisor of advisors;track advisor.id) {
              <tr (click)="modal.close(advisor)" tabindex="-1">
                <td>#{{ advisor.id }}</td>
                <td>
                  @if (advisor.name.firstName) {
                    <span>{{ advisor.name.firstName }} </span>
                  }
                  @if (advisor.name.lastName) {
                    <strong>{{ advisor.name.lastName }}</strong>
                  }
                </td>
                <td>{{ advisor.companyName }}</td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    } @else {
      <i>Could not find any advisors.</i>
    }

    @if (nullable) {
      <button class="btn btn-danger" (click)="modal.close(null)">
        <fa-icon [icon]="faTrashCan" class="me-1"></fa-icon>
        Remove advisor
      </button>
    }
  </div>
</ng-template>
